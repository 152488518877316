import styled from 'styled-components';

export const GridItem = styled.div`
  grid-column: span 4 / span 4;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 240px;
  width: 1104px;
  max-width: 1104px;
  justify-content: flex-start;
  align-items: start;
  background: white;
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  margin-top: 24px;
  padding-top: 12px;
  padding-left: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 4px;
`;

export const Title = styled.h3`
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--black);
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 0.8rem;
  font-weight: 350;
  color: var(--dark-gray);
  margin: 0;
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const TooltipContainer = styled.div`
  background: var(--white);
  padding: 2px 8px;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: #333;
`;

export const XAxisTickContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  color: var(--dark-gray);
`;

export const XAxisTickMonth = styled.span`
  font-size: 10px;
  color: var(--dark-gray);
  margin-bottom: 1px;
`;
