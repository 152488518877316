import React from 'react';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import config from '../../config/config';
import { useAuth } from '../../contexts/AuthContext';

import {
  SidebarWrapper,
  ToggleButton,
  MenuContainer,
  MenuItem,
  MenuIconWrapper,
  CollapseButton,
  ButtonWrapper,
} from './style';

const PresadaSidebar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const { logout, isAdmin } = useAuth();

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.AUTH_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        logout();
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  return (
    <SidebarWrapper collapsed={collapsed}>
      <ButtonWrapper collapsed={collapsed}>
        <ToggleButton onClick={handleToggle} collapsed={collapsed}>
          <MenuIcon />
        </ToggleButton>
        <CollapseButton onClick={handleToggle} collapsed={collapsed}>
          <ChevronLeftIcon />
        </CollapseButton>
      </ButtonWrapper>

      <MenuContainer>
        <MenuItem collapsed={collapsed} onClick={() => navigate('/welcome')}>
          <MenuIconWrapper>
            <OtherHousesOutlinedIcon />
          </MenuIconWrapper>
          {!collapsed && 'Home'}
        </MenuItem>

        <MenuItem collapsed={collapsed} onClick={() => navigate('/training')}>
          <MenuIconWrapper>
            <AddBoxOutlinedIcon />
          </MenuIconWrapper>
          {!collapsed && 'Training'}
        </MenuItem>

        <MenuItem
          collapsed={collapsed}
          onClick={() => navigate('/evaluation_history')}
        >
          <MenuIconWrapper>
            <AnalyticsOutlinedIcon />
          </MenuIconWrapper>
          {!collapsed && 'Erfolge'}
        </MenuItem>

        <MenuItem collapsed={collapsed} onClick={() => navigate('/faq')}>
          <MenuIconWrapper>
            <HelpIcon />
          </MenuIconWrapper>
          {!collapsed && 'FAQ'}
        </MenuItem>

        {isAdmin && (
          <MenuItem collapsed={collapsed} onClick={() => navigate('/settings')}>
            <MenuIconWrapper>
              <SettingsIcon />
            </MenuIconWrapper>
            {!collapsed && 'Settings'}
          </MenuItem>
        )}

        <MenuItem
          collapsed={collapsed}
          onClick={handleLogout}
          style={{
            borderTop: '3px solid var(--light-gray)',
            marginTop: '1rem',
          }}
        >
          <MenuIconWrapper>
            <LogoutIcon style={{ color: 'var(--dark-gray)' }} />
          </MenuIconWrapper>
          {!collapsed && 'Logout'}
        </MenuItem>
      </MenuContainer>
    </SidebarWrapper>
  );
};

export default PresadaSidebar;
