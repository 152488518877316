import React from 'react';
import { LoaderContainer, Loader } from './style';

const LoadingWheel = () => {
  return (
    <LoaderContainer>
      <Loader />
      <p>Loading...</p>
    </LoaderContainer>
  );
};

export default LoadingWheel;
