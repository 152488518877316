import React, { useState } from 'react';
import Banner from '../Banner';
import PresadaSidebar from '../Sidebar/Sidebar';
import { LayoutContainer, MainLayout, Content } from './style';

const Layout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <LayoutContainer>
      <Banner />
      <MainLayout collapsed={collapsed}>
        <PresadaSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content>{children}</Content>
      </MainLayout>
    </LayoutContainer>
  );
};

export default Layout;
