import styled from 'styled-components';

export const GridItem = styled.div`
  grid-row-start: 2;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  max-width: 240px;
  justify-content: flex-start;
  align-items: start;
  background: white;
  border-radius: 4px;
  margin-top: 12px;
  padding-top: 12px;
  padding-left: 24px;
  box-shadow: var(--shadow-default);
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--black);
  margin-bottom: 2vh;
`;

export const StyledArrow = styled.span`
  color: var(--turquoise);
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const RecentStatsTitle = styled.span`
  font-size: 0.7rem;
  font-weight: normal;
  color: var(--dark-gray);
  line-height: 1.2;
  margin-bottom: 8px;
`;

export const RecentStatsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  width: 95%;
`;

export const IconWrapperRecentStats = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--turquoise);
  margin-right: 4px;

  svg {
    width: 36px;
    height: 36px;
    fill: var(--turquoise);
  }
`;

export const IconWrapperMonthlyStats = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #575757;
  margin: 8px 0 8px 4px;

  svg {
    width: 28px;
    height: 28px;
    fill: #575757;
  }
`;

export const RecentStatsValue = styled.span`
  font-size: 1.8rem;
  font-weight: 400;
  margin-right: 12px;
  color: var(--turquoise);
`;

export const RecentStatsLabel = styled.span`
  font-size: 0.7rem;
  font-weight: 300;
  color: var(--dark-gray);
`;

export const MonthlyStatsTitle = styled.span`
  font-size: 0.7rem;
  font-weight: 250;
  color: var(--dark-gray);
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 12px;
`;

export const MonthlyStatsRow = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
`;

export const MonthlyStatsValue = styled.div`
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--dark-gray);
  max-width: 100%;
  margin: 0px 24px 0px 8px;
`;

export const MonthlyStatsLabel = styled.span`
  font-size: 0.7rem;
  font-weight: 200;
  color: var(--dark-gray);
`;
