import React, { useEffect, useState } from 'react';

import config from '../../config/config';

import AverageScore from '../../components/Charts/AverageScore/AverageScore';
import LoadingWheel from '../../components/LoadingWheel/LoadingWheel';
import LanguageStats from '../../components/Charts/LanguageStats/LanguageStats';
import OverallTrainingProgress from '../../components/Charts/OverallTrainingProgress/OverallTrainingProgress';
import TimeDeviation from '../../components/Charts/TimeDeviation/TimeDeviation';
import SpeechRate from '../../components/Charts/SpeechRate/SpeechRate';
import FillWordsHistory from '../../components/Charts/FillWordsHistory/FillWordsHistory';
import PausesHistory from '../../components/Charts/PausesHistory/PausesHistory';
import TrainingActivity from '../../components/Charts/TrainingActivity/TrainingActivity';
import NoDataErrorMessage from '../../components/UserErrors/NoDataErrorMessage/NoDataErrorMessage';
import ServerErrorMessage from '../../components/UserErrors/ServerErrorMessage/ServerErrorMessage';

import {
  PageContainer,
  Wrapper,
  DashboardHeader,
  ChartsGrid,
  Tabs,
  TabButton,
} from './style';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(null);

  //  Fetch evaluation data from the server
  const fetchEvaluationHistoryData = async () => {
    try {
      const response = await fetch(`${config.API_URL}/evaluation_history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server error ${response.status}:`, errorText);
        if (response.status === 401) {
          console.error('Unauthorized: Токен is expired');
          return null;
        } else if (response.status === 404) {
          console.warn('no_sessions_found'); // a 404 http error for no sessions found
          return 'no_sessions_found';
        } else {
          console.error('server_unavailable'); // a 500 http error for server unavailable
          return null;
        }
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error during request:', error);
      return null;
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchEvaluationHistoryData().then((result) => {
      if (result === 'no_sessions_found') {
        setData('no_sessions_found');
      } else {
        setData(result || null);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (data) {
      const filteredKeys = Object.keys(data).filter(
        (key) => key !== 'timestamp' // filter out the timestamp key for activeTab
      );
      const firstKey = filteredKeys[0];
      setActiveTab(firstKey);
    }
  }, [data]);

  if (loading) {
    return (
      <PageContainer>
        <DashboardHeader>Erfolge</DashboardHeader>
        <Wrapper>
          <LoadingWheel />
        </Wrapper>
      </PageContainer>
    );
  }

  // Check if the activeTab has enough Trainings data (min 5 Trainings). activeTab is the full history
  const hasEnoughTrainings =
    data &&
    data !== 'no_sessions_found' &&
    activeTab &&
    data[activeTab]?.timestamps_last_five_sessions?.length >= 5;

  if (!hasEnoughTrainings) {
    return (
      <PageContainer>
        <DashboardHeader>Erfolge</DashboardHeader>
        <Wrapper>
          <NoDataErrorMessage />
        </Wrapper>
      </PageContainer>
    );
  }

  const getDisabledTabs = (data) => {
    if (!data) return {};

    return ['de', 'en'].reduce((acc, key) => {
      const hasEnoughTrainings =
        data[key]?.timestamps_last_five_sessions?.length >= 5;
      acc[key] = !hasEnoughTrainings; // true, if the TabButton must be disabled
      return acc;
    }, {});
  };

  const disabledTabs = getDisabledTabs(data);

  return (
    <>
      <PageContainer>
        <DashboardHeader>Erfolge</DashboardHeader>

        <Wrapper>
          {!data ? (
            <ServerErrorMessage />
          ) : (
            <>
              {/* Language tabs */}
              <Tabs>
                {Object.keys(data)
                  .filter((category) => category !== 'timestamp')
                  .map((category) => (
                    <TabButton
                      key={category}
                      $isActive={activeTab === category}
                      onClick={() => {
                        if (!disabledTabs[category]) {
                          setActiveTab(category);
                        }
                      }}
                      disabled={disabledTabs[category]}
                    >
                      {category === 'full'
                        ? 'Allgemein'
                        : category === 'de'
                          ? 'Deutsch'
                          : 'Englisch'}
                    </TabButton>
                  ))}
              </Tabs>

              <ChartsGrid>
                {activeTab && data[activeTab] && (
                  <>
                    <AverageScore
                      averageScoreLastFiveSessions={
                        data[activeTab].average_score_last_five_sessions
                      }
                      averageScoreLastFourWeeks={
                        data[activeTab].average_score_last_four_weeks
                      }
                      scoreImprovement={data[activeTab].score_improvement}
                    />
                    <LanguageStats
                      germanTrainingsLastFiveSessions={
                        data[activeTab]
                          .amount_german_trainings_last_five_sessions
                      }
                      engTrainingsLastFiveSessions={
                        data[activeTab]
                          .amount_english_trainings_last_five_sessions
                      }
                      germanTrainingsPercentage={
                        data[activeTab]
                          .percentage_german_trainings_last_four_weeks
                      }
                      englishTrainingsPercentage={
                        data[activeTab]
                          .percentage_english_trainings_last_four_weeks
                      }
                    />
                    <OverallTrainingProgress
                      timestampsLastFiveSessions={
                        data[activeTab].timestamps_last_five_sessions
                      }
                      scoresLastFiveSessions={
                        data[activeTab].scores_last_five_sessions
                      }
                    />
                    <TimeDeviation
                      durDevLastFiveSessions={
                        data[activeTab]
                          .average_duration_deviation_last_five_sessions
                      }
                      durDevLastFourWeeks={
                        data[activeTab]
                          .average_duration_deviation_last_four_weeks
                      }
                      durationImprovement={data[activeTab].duration_improvemnt}
                    />
                    <SpeechRate
                      avrCadenceLastFiveSessions={
                        data[activeTab].average_cadence_last_five_sessions
                      }
                      avrCadenceLastFourWeeks={
                        data[activeTab].average_cadence_last_four_weeks
                      }
                      cadenceImprovement={data[activeTab].cadence_improvement}
                    />
                    <FillWordsHistory
                      avrFillWordsLastFiveSessions={
                        data[activeTab].average_fill_words_last_five_sessions
                      }
                      avrFillWordsLastFourWeeks={
                        data[activeTab].average_fill_words_last_four_weeks
                      }
                      fillWordsEvalLastFiveSessions={
                        data[activeTab].fill_words_eval_last_five_trainings
                      }
                      fillWordsEvalLastFourWeeks={
                        data[activeTab].fill_words_eval_last_four_weeks
                      }
                      fillWordsImprovement={
                        data[activeTab].fill_words_improvement
                      }
                    />
                    <PausesHistory
                      avrPausesLastFiveSessions={
                        data[activeTab].average_pauses_last_five_sessions
                      }
                      avrPausesLastFourWeeks={
                        data[activeTab].average_pauses_last_four_weeks
                      }
                      avrPausesEvalLastFiveSessions={
                        data[activeTab].average_pauses_eval_last_five_trainings
                      }
                      avrPausesEvalLastFourWeeks={
                        data[activeTab].average_pauses_eval_last_four_weeks
                      }
                      pausesImprovement={data[activeTab].pauses_improvement}
                    />
                    <TrainingActivity
                      trainingTimesLastFourWeeks={
                        data[activeTab].training_times_last_four_weeks
                      }
                    />
                  </>
                )}
              </ChartsGrid>
            </>
          )}
        </Wrapper>
      </PageContainer>
    </>
  );
};

export default Dashboard;
