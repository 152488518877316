// src/pages/Login.js
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Login.css';
import Banner from '../components/Banner';
import config from '../config/config';
import { useAuth } from '../contexts/AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { login } = useAuth(); // Access the login function from AuthContext
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError('');
    try {
      const response = await fetch(`${config.AUTH_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include', // Include cookies
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }

      const data = await response.json();

      // Redirect to the welcome page
      await login(data.isAdmin);
      navigate('/welcome');
    } catch (error) {
      setError(error.message);
      console.error('Login error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    handleLogin();
  };

  return (
    <div className="login-page">
      <Banner />
      <div className="login-content">
        <div className="tagline">
          <p>Your</p>
          <p>
            personal <span className="highlight turquoise">AI</span>
          </p>
          <p>
            <span className="highlight turquoise">communication</span>
          </p>
          <p>coach</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="login-form-container">
            <div className="login-form">
              <label htmlFor="username">Email:</label>
              <input
                type="text"
                id="username"
                placeholder="your.email@domain.com"
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="password">Passwort:</label>
              <input
                type="password"
                id="password"
                placeholder="********"
                onChange={(e) => setPassword(e.target.value)}
              />
              <a href="/forgot-password" className="forgot-password">
                Passwort vergessen?
              </a>
            </div>
            <button type="submit" className="login-button">
              Login
            </button>

            {error && <p className="error">{error}</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
