import styled, { keyframes } from 'styled-components';

/* Training Page Layout */
export const TrainingPage = styled.div`
  text-align: center;
  height: var(--height-without-banner);
  overflow-y: auto;
`;

/* Form Styling */
export const TrainingForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-default);
  max-width: 600px;
  margin: 0 auto;
`;

/* Form Container */
export const Container = styled.div`
  gap: 1rem;
  padding: 20px;
  max-width: 800px;
  width: 800px;
  margin: 0 auto;
  border: 2px solid var(--white);
  background-color: var(--white);
  bborder-radius: var(--border-radius);
  box-shadow: var(--shadow-default);
  position: relative;
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;

/* Button Group */
export const ButtonGroup = styled.div`
  display: flex;
  flex-basis: 100%;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
`;

/* Audio Visualization */
export const AudioVisualization = styled.canvas`
  background-color: var(--white);
  border: 1px solid var(--mid-gray);
  border-radius: var(--border-radius);
  display: block;
  margin: 10px auto;
`;

/* Recording Image */
export const RecordingImage = styled.img`
  max-width: 70%;
  max-height: 70%;
  object-fit: cover;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

/* Video Feed */
export const VideoFeed = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border: 2px solid var(--turquoise);
  border-radius: var(--border-radius);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`;

/* Video Element inside Video Feed */
export const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

/* Keyframe Animation */
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinningElement = styled.div`
  animation: ${spin} 1s linear infinite;
`;

export const Label = styled.label`
  flex: 0 0 150px;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;
