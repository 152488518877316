export const dialogStyles = {
  '& .MuiPaper-root': {
    boxShadow: 'none',
    border: 'none',
  },
};

export const dialogContentStyles = {
  paddingTop: 0,
  border: 'none',
};

export const tableCellStyles = {
  padding: '12px',
  borderBottom: 'none',
};

export const tableRowStyles = (hasError) => ({
  backgroundColor: hasError ? '#ffebee' : 'var(--light-gray)',
  overflow: 'hidden',
  '& td:first-of-type': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '& td:last-of-type': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
});

export const errorTextStyles = {
  color: 'red',
  fontSize: '12px',
  paddingTop: '4px',
  borderBottom: 'none',
};

export const errorCellStyles = {
  ...tableCellStyles,
  padding: '0',
  paddingBottom: '12px',
};

export const tableStyles = {
  borderCollapse: 'separate',
  borderSpacing: '0 8px',
};

export const tableHeadStyles = {
  '& .MuiTableCell-root': { borderBottom: 'none' },
};
