import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import {
  OverallScoreCard,
  OverallScoreTitle,
  RecentStatsWrapper,
  RecentStatsValue,
  RecentStyledScore,
} from './style';
import './Evaluate.css';

const colorMap = {
  5: '--best',
  4: '--good',
  3: '--neutral',
  2: '--bad',
};

function hexToRgba(hex, alpha = 1) {
  // Remove the hash if present
  hex = hex.replace('#', '');

  // Parse r, g, b values from the hex string
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function getCssVariableValue(variable) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
}

const CircularProgress = ({
  plannedTimeInSeconds,
  actualTimeInSeconds,
  durationScore,
}) => {
  // Calculate the progress percentage
  const percentage = Math.min(
    (actualTimeInSeconds / plannedTimeInSeconds) * 100,
    100
  );
  // Circle properties
  const radius = 50; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const strokeDashoffset = circumference - (percentage / 100) * circumference; // Calculate the offset

  let colorVal = colorMap[durationScore] || '--worst';

  return (
    <div className="circular-progress-container">
      <svg width="120" height="120">
        <circle
          cx="60"
          cy="60"
          r={radius * 1.2}
          fill={hexToRgba(getCssVariableValue(colorVal), 0.1)}
        />
        {/* Background Circle */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke="var(--white)"
          strokeWidth="10"
        />
        {/* Progress Circle */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke={`var(${colorVal})`}
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          style={{ transition: 'stroke-dashoffset 0.5s ease' }}
        />
      </svg>
      <div className="progress-text">
        <p>Zeit</p>
      </div>
    </div>
  );
};

const CircularGraph = ({ data, maxFillWords }) => {
  const colors = [
    'var(--dark-violet)',
    'var(--orange)',
    'var(--green)',
    'var(--dark-gray)',
    'var(--turquoise)',
  ]; // Colors for the circles
  const baseRadius = 90; // Starting radius
  const spacing = 20; // Space between each circle

  return (
    <svg width="200" height="200" viewBox="0 0 200 200">
      {data.map((item, index) => {
        const radius = baseRadius - index * spacing; // Concentric circles with increased spacing
        const circumference = 2 * Math.PI * radius;
        const percentage = (item.count / maxFillWords) * 100;
        const strokeDasharray = `${
          (circumference * percentage) / 100
        } ${circumference}`;

        let color;
        if (percentage <= 100) {
          color = colors[index % colors.length];
        } else {
          color = 'var(--worst)';
        }

        return (
          <g key={index}>
            {/* Gray background circle */}
            <circle
              cx="100"
              cy="100"
              r={radius}
              fill="none"
              stroke="var(--light-gray)" // Background gray color
              strokeWidth="10"
            />
            {/* Colored progress circle */}
            <circle
              cx="100"
              cy="100"
              r={radius}
              fill="none"
              stroke={color}
              strokeWidth="10"
              strokeDasharray={strokeDasharray}
              strokeDashoffset="25"
              transform="rotate(-90 100 100)"
            />
          </g>
        );
      })}
    </svg>
  );
};

const FillWordsDisplay = ({ fillWords, maxFillWords }) => {
  const data = Object.entries(fillWords).map(([word, count]) => ({
    word,
    count,
  }));

  return (
    <div>
      {data.length > 0 ? (
        <div className="fillword_container">
          <div className="circular-progress-container">
            <CircularGraph data={data} maxFillWords={maxFillWords} />
          </div>
          <div className="fillword_counts">
            {data.map((item, index) => (
              <div key={index}>
                <strong style={{ color: item.color }}>{item.word}</strong>
                <br />
                {item.count}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <p>Großartig, es wurden keine Füllwörter detektiert!</p>
        </div>
      )}
    </div>
  );
};

const SpeedChart = ({ cadenceData }) => {
  const legendFormatter = (value) => {
    // Replace `speed` with a translated label
    const labelMapping = {
      speed: 'Wörter / Minute',
    };
    return labelMapping[value] || value; // Default to original label if no mapping found
  };
  return (
    <div className="row-white graph-row">
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={cadenceData}
          margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey="time"
            axisLine={{ stroke: '#e0e0e0' }}
            tick={{ fontSize: 12 }}
            tickLine={false}
          />
          <YAxis
            tickFormatter={(tick) => `${tick}`}
            axisLine={{ stroke: '#e0e0e0' }}
            tick={{ fontSize: 12 }}
            tickLine={false}
          />
          <Tooltip formatter={(value) => `${parseInt(value)} wpm`} />
          <Line
            type="monotone"
            dataKey="speed"
            stroke="var(--turquoise)"
            strokeWidth={3}
            dot={{ stroke: 'var(--turquoise)', r: 4 }}
          />
          <Legend
            verticalAlign="top" // Align the legend at the top
            align="right" // Center-align the legend horizontally
            formatter={legendFormatter}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const ProgressBar = ({ value, maxValue, evaluationValue }) => {
  const progress = (value / maxValue) * 100;
  let bar_width = 0;

  if (progress > 100) {
    bar_width = 100;
  } else {
    bar_width = progress;
  }

  const colorString = colorMap[evaluationValue] || '--worst';
  const color = hexToRgba(getCssVariableValue(colorString), 1);

  return (
    <div className="progress-container">
      <div className="progress-label">Anzahl Pausen</div>
      <div className="progress-bar-wrapper">
        <div
          className="progress-bar"
          style={{ width: `${bar_width}%`, backgroundColor: `${color}` }}
        ></div>
      </div>
      <div className="progress-value">{value}</div>
    </div>
  );
};

const EvaluationResult = ({
  onHome,
  onNewTraining,
  data,
  trainingSettings,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const fillWords = JSON.parse(data.fill_words);
  const maxFillWords = JSON.parse(data.max_fill_words);
  const cadenceData = data.cadence.map((value, index) => ({
    time: data.cadence_intervall[index],
    speed: parseFloat(value),
  }));
  const desiredPauses = JSON.parse(data.max_long_pauses);
  const actualPauses = JSON.parse(data.amount_long_pauses);
  const scorePauses = JSON.parse(data.long_pause_val);
  const overallScore = JSON.parse(data.overall_score);

  const formatTime = (timeString) => {
    const parts = timeString.split(':').map(Number);
    if (parts.length === 1) {
      const seconds = parseInt(timeString, 10);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )}:${String(remainingSeconds).padStart(2, '0')}`;
      } else {
        return `${String(minutes).padStart(2, '0')}:${String(
          remainingSeconds
        ).padStart(2, '0')}`;
      }
    } else if (parts.length === 2) {
      // Format is MM:SS or M:SS
      const [minutes, seconds] = parts;
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`;
    } else if (parts.length === 3) {
      // Format is HH:MM:SS
      const [hours, minutes, seconds] = parts;
      if (hours === 0) {
        // Less than 60 minutes, format as MM:SS
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
          2,
          '0'
        )}`;
      } else {
        // More than 60 minutes, format as HH:MM:SS
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )}:${String(seconds).padStart(2, '0')}`;
      }
    }
  };

  function timeStringToSeconds(timeString) {
    if (!timeString || typeof timeString !== 'string') return 0;

    const parts = timeString.split(':').map((part) => part.trim());
    if (!parts.every((part) => /^\d+$/.test(part))) {
      throw new Error(`Invalid time string: ${timeString}`);
    }

    return parts.reduce((acc, time) => 60 * acc + +time, 0);
  }

  const formattedPlannedTime = formatTime(data.planned_duration);
  const formattedActualTime = formatTime(data.duration);

  const plannedTimeInSeconds = timeStringToSeconds(formattedPlannedTime);
  const actualTimeInSeconds = timeStringToSeconds(formattedActualTime);

  return (
    <div className="eval_container">
      <div className="columns-container">
        <div className="left-column">
          <div className="element">
            <div className="header">Überblick</div>
            <div className="row overview">
              <OverallScoreCard>
                <OverallScoreTitle>Gesamtscore</OverallScoreTitle>
                <RecentStatsWrapper>
                  <RecentStatsValue>{overallScore.toFixed(1)}</RecentStatsValue>
                  <RecentStyledScore
                    value={overallScore}
                    precision={0.1}
                    readOnly
                  />
                </RecentStatsWrapper>
              </OverallScoreCard>
              <div className="time">
                <div className="time-text">
                  <h2>{formattedPlannedTime}</h2>
                  <h4>Geplant</h4>
                </div>
                <div className="circular-progress">
                  <CircularProgress
                    plannedTimeInSeconds={plannedTimeInSeconds}
                    actualTimeInSeconds={actualTimeInSeconds}
                    durationScore={data.duration_val}
                  />
                </div>

                <div className="time-text">
                  <h2>{formattedActualTime}</h2>
                  <h4>Tatsächlich</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="element">
            <div className="header">Sprechgeschwindigkeit</div>
            <SpeedChart cadenceData={cadenceData} />
          </div>

          <div className="element">
            <div className="header">Transkript</div>
            <div className="row-white transcript-row">
              <div
                className={`transcript-content ${
                  isExpanded ? 'expanded' : 'collapsed'
                }`}
              >
                {data.summary}
              </div>
              <span
                className="more-link"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? ' <weniger>' : ' <mehr>'}
              </span>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="element">
            <div className="header">Füllwörter</div>
            <div className="row-white fillword-graph">
              <FillWordsDisplay
                fillWords={fillWords}
                maxFillWords={maxFillWords}
              />
            </div>
          </div>

          <div className="element">
            <div className="header">Pausen</div>
            <div className="row-white pauses-graph">
              <ProgressBar
                value={actualPauses}
                maxValue={desiredPauses}
                evaluationValue={scorePauses}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="header">
        <div className="button-group">
          <button type="button" className="button" onClick={onNewTraining}>
            Neues Training
          </button>
          <button type="button" className="button" onClick={onHome}>
            Zurück zur Übersicht
          </button>
        </div>
      </div>
    </div>
  );
};

export default EvaluationResult;
