import styled from 'styled-components';

export const GridItem = styled.div`
  grid-column: span 2 / span 2;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 240px;
  width: 528px;
  max-width: 528px;
  justify-content: flex-start;
  align-items: start;
  background: white;
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  padding-top: 12px;
  padding-left: 24px;
  && h3 {
    margin: 0;
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h3`
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--black);
`;

export const Subtitle = styled.p`
  font-size: 0.8rem;
  font-weight: 350;
  color: #575757;
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 4px;
`;
