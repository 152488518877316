import React from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { StyledDialog, DialogButton } from './style';

const AbortDialog = ({ open, onClose, onConfirm }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledDialog
      fullScreen={fullScreen}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="abort-dialog-title"
      aria-describedby="abort-dialog-description"
      disableEscapeKeyDown
    >
      <DialogTitle id="abort-dialog-title">
        Wollen Sie wirklich abbrechen?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="abort-dialog-description">
          Dadurch geht Ihr aktuelles Training verloren. Die Daten werden nicht
          verarbeitet und fließen nicht in die Übersicht mit ein.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onConfirm} $isCancel autoFocus>
          Abbrechen
        </DialogButton>
        <DialogButton autoFocus onClick={onClose}>
          Fortsetzen
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default AbortDialog;
