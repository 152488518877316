import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import './Training.css';
import { TrainingPage } from './style';

import TrainingSettings from './training_subpages/TrainingSettings/TrainingSettings';
import DeviceSetup from './training_subpages/DeviceSetup/DeviceSetup';
import Countdown from '../../components/Countdown/Countdown';
import Recording from './training_subpages/Recording/Recording';
import LoadingWheel from '../../components/LoadingWheel/LoadingWheel';
import EvaluationResult from './training_subpages/Evaluate/Evaluate';
import config from '../../config/config';
import WebRTCManager from '../../utils/WebRTCManager';
import Questionnaire from './training_subpages/Questionnaire/Questionnaire';

import Button from '../../components/Button/Button';

const TRAINING_PHASES = {
  SETTINGS: 'settings',
  DEVICE_SETUP: 'device_setup',
  COUNTDOWN: 'countdown',
  RECORDING: 'recording',
  WAITING_FOR_RESULT: 'waiting_for_result',
  EVALUATION: 'evaluation',
  ERROR: 'error',
};

const STOP_MEDIA_PHASES = [
  TRAINING_PHASES.SETTINGS,
  TRAINING_PHASES.EVALUATION,
  TRAINING_PHASES.ERROR,
];

const Training = () => {
  const [phase, setPhase] = useState(TRAINING_PHASES.SETTINGS); // Current phase of the training
  const [count, setCount] = useState(4); // Countdown timer state
  const [formData, setFormData] = useState(null); // Form data submitted by the user
  const [mediaStream, setMediaStream] = useState(null); // Media stream for audio/video
  const [evalData, setEvalData] = useState(null); // Evaluation data from the server
  const [connectionStatus, setConnectionStatus] = useState('connecting'); // Default to 'connecting'
  const [selectedCamera, setSelectedCamera] = useState('');
  const [selectedMicrophone, setSelectedMicrophone] = useState('');
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');
  const cancelConnectionEstablishment = useRef(false);

  const navigate = useNavigate();

  const handleMessage = (event) => {
    if (event.data === 'backend_stop') {
      webRTCManagerRef.current.peerConnection.close();
    }
    if (event.data === 'backend_error') {
      webRTCManagerRef.current.peerConnection.close();
      console.error('Backend error - connection');
      setPhase(TRAINING_PHASES.ERROR);
    }
  };

  // WebRTC Manager
  const webRTCManagerRef = useRef(null);
  const sessionIdRef = useRef(null);

  useEffect(() => {
    if (!webRTCManagerRef.current) {
      webRTCManagerRef.current = new WebRTCManager(config, handleMessage);
    }
  }, []);

  /**
   * Establishes a connection using a WebRTC manager with retry logic.
   *
   * This asynchronous function attempts to create an SDP offer and returns a session ID
   * by sending the offer. If either step fails, it retries the process with exponential backoff
   * until the maximum number of retries is reached. Upon success, it tracks the connection and updates
   * the connection status to "connected". If the retry limit is exceeded, it sets the connection status
   * to "timeout" and the phase to "error". This retry logic was implemented, since we identified
   * connection issues on Mac OS and Windows OS devices due to incomplete ICE gathering states.
   *
   * @param {*} data - The data required to establish the connection.
   * @param {number} [maxRetries=3] - The maximum number of retry attempts.
   * @param {number} [attempt=0] - The current attempt count (used internally for recursion).
   * @returns {Promise<void>} A promise that resolves when the connection process is complete.
   */
  const establishConnection = async (data, maxRetries = 3, attempt = 0) => {
    if (cancelConnectionEstablishment.current) {
      return Promise.resolve();
    }
    try {
      const offerSuccess = await webRTCManagerRef.current.createSDPOffer();
      if (!offerSuccess) throw new Error('SDP offer creation failed on retry');

      const sessionId = await webRTCManagerRef.current.sendSDPOffer(data);
      if (!sessionId) throw new Error('Session creation failed');
      sessionIdRef.current = sessionId;

      await webRTCManagerRef.current.trackConnection();
      setConnectionStatus('connected');
    } catch (error) {
      if (attempt < maxRetries) {
        let delay = Math.pow(2, attempt) * 1000 + Math.random() * 1000;
        console.debug(
          `Attempt ${attempt + 1} failed with error ${error}; retrying in ${delay} ms`
        );
        await new Promise((res) => setTimeout(res, delay));
        // Important: we need the return keyword here, since all async functions return a promise.
        // Otherwise we run into an infinite loop, since we have problem in the promise chain.
        return await establishConnection(data, maxRetries, attempt + 1);
      } else {
        console.error(
          'Max retries reached. Connection could not be established:',
          error
        );
        setConnectionStatus('timeout');
        setPhase(TRAINING_PHASES.ERROR);
      }
    }
  };

  // Cleanup media stream when phase changes to certain states
  const cleanupMediaStream = () => {
    setMediaStream((prevStream) => {
      if (prevStream) {
        prevStream.getTracks().forEach((track) => track.stop());
      }
      return null;
    });
  };

  // Also added the logic to cleanup the media stream when the selected devices are deselected
  useEffect(() => {
    if (STOP_MEDIA_PHASES.includes(phase)) {
      cleanupMediaStream();
    }
    // Don't cleanup media stream if we're in the device setup phase and have selected devices
    if (
      phase === TRAINING_PHASES.DEVICE_SETUP &&
      !selectedCamera &&
      !selectedMicrophone &&
      mediaStream
    ) {
      cleanupMediaStream();
    }
  }, [phase, selectedCamera, selectedMicrophone, mediaStream]);

  const handleExerciseNow = (data) => {
    setFormData(data);
    // check if device preferences are available if they haven't been set yet
    if (!selectedCamera && sessionStorage.getItem('presada:selectedCamera')) {
      setSelectedCamera(sessionStorage.getItem('presada:selectedCamera'));
    }
    if (
      !selectedMicrophone &&
      sessionStorage.getItem('presada:selectedMicrophone')
    ) {
      setSelectedMicrophone(
        sessionStorage.getItem('presada:selectedMicrophone')
      );
    }

    setPhase(TRAINING_PHASES.DEVICE_SETUP); // Proceed immediately to the next phase

    // Ensure that we are establishing the connection also recursivly in case of an error
    cancelConnectionEstablishment.current = false;
    establishConnection(data, 3, 0);
  };

  const handleStart = () => {
    if (!mediaStream) {
      console.error('No mediaStream available at start!');
      return; // Abort if no media stream is available
    }

    if (mediaStream) {
      mediaStream
        .getAudioTracks()
        .forEach((audioTrack) =>
          webRTCManagerRef.current.replaceTrack('audio', audioTrack)
        );
      // mediaStream
      //   .getVideoTracks()
      //   .forEach((videoTrack) =>
      //     webRTCManagerRef.current.replaceTrack('video', videoTrack)
      //   );
    }
    setPhase(TRAINING_PHASES.COUNTDOWN);
    setCount(4);
  };

  const handleStartRecording = useCallback(() => {
    setPhase(TRAINING_PHASES.RECORDING);
    webRTCManagerRef.current.sendMessage('start');
  }, [setPhase, webRTCManagerRef]);

  const handleStopRecording = () => {
    webRTCManagerRef.current.sendMessage('stop');
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setMediaStream(null);
    navigate('/welcome');
  };

  const handleBack = () => {
    if (phase === TRAINING_PHASES.DEVICE_SETUP) {
      // stop connection attempts - stop establishing connection routine
      cancelConnectionEstablishment.current = true;
      webRTCManagerRef.current.close();
      setPhase(TRAINING_PHASES.SETTINGS);
    } else if (phase === TRAINING_PHASES.EVALUATION) {
      setShowQuestionnaire(true);
      setNavigationPath('/welcome');
    } else if (
      [TRAINING_PHASES.SETTINGS, TRAINING_PHASES.ERROR].includes(phase)
    ) {
      navigate('/welcome');
    }
  };

  const handleQuestionnaireSubmit = (data) => {
    if (data) {
      // send data to backend and store in DB
      try {
        fetch(`${config.API_URL}/questionnaire`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(data),
        });
      } catch (error) {
        console.error('Error submitting questionnaire:', error);
      }
    }

    setShowQuestionnaire(false);
    navigate(navigationPath);
    setNavigationPath('');
    setPhase(TRAINING_PHASES.SETTINGS);
  };

  const handleNewTraining = () => {
    if (phase === TRAINING_PHASES.EVALUATION) {
      setShowQuestionnaire(true);
      setNavigationPath('/training');
    }
    if (STOP_MEDIA_PHASES.includes(phase)) {
      cleanupMediaStream();
    }
  };

  // Countdown handling
  useEffect(() => {
    if (phase === TRAINING_PHASES.COUNTDOWN && count > 0) {
      const timer = setTimeout(() => setCount((prev) => prev - 1), 1000);
      return () => clearTimeout(timer); // Cleanup timer on phase change
    } else if (phase === TRAINING_PHASES.COUNTDOWN && count === 0) {
      handleStartRecording(); // Transition to recording phase
    }
  }, [count, phase, handleStartRecording]);

  const handleEvaluate = () => {
    if (phase === TRAINING_PHASES.RECORDING) {
      webRTCManagerRef.current.sendMessage('stop');
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(null);
      }
      setPhase(TRAINING_PHASES.WAITING_FOR_RESULT);
    }
  };

  // Stop media stream when navigating away (browser back button)
  useEffect(() => {
    const handlePopState = () => {
      setPhase(TRAINING_PHASES.SETTINGS);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setMediaStream]);

  const getStatus = useCallback(async () => {
    const sessionId = sessionIdRef.current;
    try {
      const response = await fetch(`${config.API_URL}/status_processing`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ session_id: sessionId }),
      });

      if (!response.ok) {
        console.error('Failed to fetch status:', response.status);
        return null;
      }

      const statusResult = await response.json();
      return statusResult;
    } catch (error) {
      console.error('Error polling status:', error);
      return null;
    }
  }, []);

  const pollStatus = useCallback(async () => {
    let isFinished = false;
    const maxAttempts = 10;
    let attempts = 0;
    let statusResult = null;

    while (!isFinished && attempts < maxAttempts) {
      try {
        statusResult = await getStatus();
        if (statusResult?.status === 'finished') {
          isFinished = true;
        } else {
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1500)); // Wait 1.5 seconds
        }
      } catch (error) {
        console.error('Error polling status:', error);
        break;
      }
    }

    if (!isFinished && attempts >= maxAttempts) {
      console.error('Status did not finish within the allocated time.');
      setPhase(TRAINING_PHASES.ERROR);
    }

    return statusResult;
  }, [getStatus]);

  // Phase-dependent logic
  useEffect(() => {
    if (phase === TRAINING_PHASES.WAITING_FOR_RESULT) {
      pollStatus().then((result) => {
        if (result?.status === 'finished') {
          fetchEvaluationData().then((data) => {
            setEvalData(data);
            if (data) {
              setPhase(TRAINING_PHASES.EVALUATION);
            }
          });
        } else {
          setPhase(TRAINING_PHASES.ERROR);
        }
      });
    }
  }, [phase, mediaStream, pollStatus]);

  //  Fetch evaluation data from the server
  const fetchEvaluationData = async () => {
    const sessionId = sessionIdRef.current;
    try {
      const response = await fetch(`${config.API_URL}/evaluation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ session_id: sessionId }),
      });

      if (!response.ok) {
        console.error('Failed to fetch evaluation data:', response.status);
        setPhase(TRAINING_PHASES.ERROR);
        return null;
      }

      const data = await response.json();
      setEvalData(data);
      return data;
    } catch (error) {
      console.error('Error fetching evaluation data:', error);
      setPhase(TRAINING_PHASES.ERROR);
      return null;
    }
  };

  return (
    <TrainingPage>
      <h1>Training</h1>
      {phase === TRAINING_PHASES.SETTINGS && (
        <TrainingSettings
          onExerciseNow={handleExerciseNow}
          handleBack={handleBack}
        />
      )}
      {phase === TRAINING_PHASES.DEVICE_SETUP && (
        <DeviceSetup
          onStart={handleStart}
          onBack={handleBack}
          mediaStream={mediaStream}
          setMediaStream={setMediaStream}
          connectionStatus={connectionStatus}
          selectedCamera={selectedCamera}
          setSelectedCamera={setSelectedCamera}
          selectedMicrophone={selectedMicrophone}
          setSelectedMicrophone={setSelectedMicrophone}
        />
      )}
      {phase === TRAINING_PHASES.COUNTDOWN && <Countdown count={count} />}
      {phase === TRAINING_PHASES.RECORDING && (
        <Recording
          formData={formData}
          mediaStream={mediaStream}
          onStop={handleStopRecording}
          onEvaluate={handleEvaluate}
        />
      )}
      {phase === TRAINING_PHASES.WAITING_FOR_RESULT && <LoadingWheel />}
      {phase === TRAINING_PHASES.EVALUATION && (
        <EvaluationResult
          onHome={handleBack}
          onNewTraining={handleNewTraining}
          data={evalData}
          trainingSettings={formData}
        />
      )}
      {phase === TRAINING_PHASES.ERROR && (
        <div>
          <h2>
            Entschuldigen Sie, es ist ein unerwarteter Fehler aufgetreten.
          </h2>
          <Button onClick={handleBack}>Zurück</Button>
        </div>
      )}
      {showQuestionnaire && (
        <Questionnaire
          onSubmit={handleQuestionnaireSubmit}
          isVisible={showQuestionnaire}
        />
      )}
    </TrainingPage>
  );
};

export default Training;
