import { VscTriangleUp, VscTriangleDown } from 'react-icons/vsc';
import { TiMinus } from 'react-icons/ti';
import { getStyledSmileIcon } from '../../../utils/evaluationStyles';

import {
  GridItem,
  Card,
  Title,
  StyledArrow,
  RecentStatsTitle,
  RecentStatsRow,
  IconWrapperRecentStats,
  RecentStatsValue,
  RecentStatsLabel,
  MonthlyStatsTitle,
  MonthlyStatsRow,
  IconWrapperMonthlyStats,
  MonthlyStatsValue,
  MonthlyStatsLabel,
} from './style';

const FillWordsHistory = ({
  avrFillWordsLastFiveSessions,
  avrFillWordsLastFourWeeks,
  fillWordsEvalLastFiveSessions,
  fillWordsEvalLastFourWeeks,
  fillWordsImprovement,
}) => {
  return (
    <GridItem>
      <Card>
        <Title>
          Füllwörter
          <StyledArrow>
            {fillWordsImprovement > 0 ? (
              <VscTriangleUp />
            ) : fillWordsImprovement < 0 ? (
              <VscTriangleDown />
            ) : (
              <TiMinus />
            )}
          </StyledArrow>
        </Title>
        <RecentStatsTitle>Die letzten 5 Trainings</RecentStatsTitle>
        <RecentStatsRow>
          <IconWrapperRecentStats>
            {getStyledSmileIcon(fillWordsEvalLastFiveSessions)}
          </IconWrapperRecentStats>

          <RecentStatsValue>{avrFillWordsLastFiveSessions}</RecentStatsValue>
          <RecentStatsLabel>
            {avrFillWordsLastFiveSessions === 1 ? 'Füllwort' : 'Füllwörter'}
            <br /> pro Minute
          </RecentStatsLabel>
        </RecentStatsRow>

        <MonthlyStatsTitle>4-Wochen-Durchschnitt</MonthlyStatsTitle>
        <MonthlyStatsRow>
          <IconWrapperMonthlyStats>
            {getStyledSmileIcon(fillWordsEvalLastFourWeeks)}
          </IconWrapperMonthlyStats>
          <MonthlyStatsValue>{avrFillWordsLastFourWeeks}</MonthlyStatsValue>
          <MonthlyStatsLabel>
            {avrFillWordsLastFourWeeks === 1 ? 'Füllwort' : 'Füllwörter'}
            <br /> pro Minute
          </MonthlyStatsLabel>
        </MonthlyStatsRow>
      </Card>
    </GridItem>
  );
};

export default FillWordsHistory;
