import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  max-width: 800px;
  width: 800px;
  margin: 0 auto;
  border: 2px solid var(--white);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-default);
  position: relative;
`;

export const Input = styled.select`
  font-size: 1rem;
  background-color: var(--white);
  color: var(--black);
  width: auto;
  max-width: 100%;
  padding: 8px;
  border: 1px solid var(--white);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & option:hover {
    background-color: var(--turquoise);
    color: var(--white);
  }
`;

export const OptionGroup = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Option = styled.div`
  padding: 10px 15px;
  background-color: ${({ selected }) =>
    selected ? 'var(--turquoise)' : 'var(--light-gray)'};
  border: 1px solid
    ${({ selected }) => (selected ? 'var(--turquoise)' : 'var(--light-gray)')};
  border-radius: var(--border-radius);
  cursor: pointer;
  text-align: center;
  transition:
    background-color 0.2s,
    color 0.2s;
  user-select: none;
  font-size: 1rem;
  color: ${({ selected }) => (selected ? 'var(--white)' : 'inherit')};

  &:hover {
    ${({ selected }) =>
      !selected &&
      `
        background-color: var(--mid-gray);
      `}
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  gap: 10%;
`;

export const Slider = styled.input`
  flex: 1;
  appearance: none;
  height: 8px;
  background: var(--mid-gray);
  border-radius: var(--border-radius);
  outline: none;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: var(--turquoise);
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: var(--turquoise);
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const SliderValue = styled.div`
  font-size: 1rem;
  white-space: nowrap;
  flex-shrink: 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end; /* Move buttons to the right */
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
`;

export const themeContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const themeImageStyles = {
  marginLeft: '10px',
  width: '50%',
  height: 'auto',
};
