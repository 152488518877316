import React, { useEffect, useRef, useState } from 'react';
import VideocamOff from '@mui/icons-material/VideocamOff';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import AbortDialog from '../../../../components/AbortDialog/AbortDialog';
import Button from '../../../../components/Button/Button';
import { ButtonGroup, Container } from '../../style';
import {
  ImageAndArrowsWrapper,
  RecordingImageContainer,
  RecordingImage,
  VideoFeed,
  Timer,
  StyledVideo,
  ArrowPlaceholder,
} from './style';

import tourData from '../../../../assets/data/tourThemes.json';
import roomNames from '../../../../assets/data/roomNames.json';

const Recording = ({ formData, mediaStream, onStop, onEvaluate }) => {
  const videoRef = useRef(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTimerPaused, setIsTimerPaused] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const roomList = tourData[formData.theme].rooms;

  // laod all the room images from the roomList
  const imagesData = roomList.map((room) => {
    return {
      name: room,
      title: roomNames[room],
      src: require(`../../../../assets/themes/${room}.jpg`),
    };
  });

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  useEffect(() => {
    if (isTimerPaused) return;
    const timer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [isTimerPaused]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');
  };

  const handleNextImage = () => {
    setCurrentIndex((prev) => (prev < imagesData.length - 1 ? prev + 1 : prev));
  };

  const handlePrevImage = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
    setIsTimerPaused(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsTimerPaused(false);
  };

  const handleConfirmAbort = () => {
    setIsDialogOpen(false);
    onStop();
  };

  return (
    <Container>
      <Timer>
        <h2>{formatTime(elapsedTime)}</h2>
      </Timer>

      <ImageAndArrowsWrapper>
        <ArrowPlaceholder>
          {currentIndex > 0 && (
            <Button onClick={handlePrevImage}>
              <ArrowBackIosOutlinedIcon fontSize="large" />
            </Button>
          )}
        </ArrowPlaceholder>
        <RecordingImageContainer>
          <RecordingImage>
            <img
              src={imagesData[currentIndex].src}
              alt={imagesData[currentIndex].title}
            />
          </RecordingImage>
          {imagesData[currentIndex].title}
        </RecordingImageContainer>
        <ArrowPlaceholder>
          {currentIndex < imagesData.length - 1 && (
            <Button onClick={handleNextImage}>
              <ArrowForwardIosOutlinedIcon fontSize="large" />
            </Button>
          )}
        </ArrowPlaceholder>
      </ImageAndArrowsWrapper>

      <VideoFeed>
        {mediaStream && mediaStream.getVideoTracks().length > 0 ? (
          <StyledVideo ref={videoRef} autoPlay muted playsInline />
        ) : (
          <div>
            <VideocamOff fontSize="large" color="disabled" />
          </div>
        )}
      </VideoFeed>
      <ButtonGroup>
        <Button type="button" onClick={handleDialogOpen}>
          Abbruch
        </Button>
        <Button type="button" onClick={onEvaluate}>
          Zur Auswertung
        </Button>
      </ButtonGroup>
      <AbortDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleConfirmAbort}
      />
    </Container>
  );
};

export default Recording;
