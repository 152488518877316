import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  PageContainer,
  FAQHeader,
  Wrapper,
  Tabs,
  TabButton,
  QuestionsContainer,
  QuestionWrapper,
  QuestionTitle,
  QuestionAnswer,
  IconWrapper,
  ReferencesContainer,
  TableContainer,
} from './style';
import faqData from '../../assets/data/faqData.json';

const QuestionAnswerTable = ({ table }) => {
  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {table.header.map((heading, index) => (
              <th key={index} dangerouslySetInnerHTML={{ __html: heading }} />
            ))}
          </tr>
        </thead>
        <tbody>
          {table.content.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  dangerouslySetInnerHTML={{ __html: cell }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

const References = ({ references }) => {
  return (
    <ReferencesContainer>
      <h4>Quellen</h4>
      <ul>
        {references.map((ref) => (
          <li key={ref.id}>
            <sup>[{ref.id}]</sup>{' '}
            <a href={ref.url} target="_blank" rel="noopener noreferrer">
              {ref.text}
            </a>
          </li>
        ))}
      </ul>
    </ReferencesContainer>
  );
};

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState({}); // Object to store open questions
  const [activeTab, setActiveTab] = useState(Object.keys(faqData)[0]);
  const questionRefs = useRef({}); // Ref for questions
  const containerRef = useRef(null); // Ref for QuestionsContainer

  // Scroll to the top of the container when the active tab changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [activeTab]);

  // Scroll to the last opened question when the openQuestions state changes
  useEffect(() => {
    // Get all keys of open questions (keys where value is true)
    const openKeys = Object.keys(openQuestions).filter(
      (key) => openQuestions[key]
    );

    // Check if there is at least one open question
    if (openKeys.length > 0) {
      // Get the last opened question key
      const lastOpenedKey = openKeys[openKeys.length - 1];

      // Get the corresponding DOM element using the questionRefs
      const questionElement = questionRefs.current[lastOpenedKey];

      if (questionElement) {
        // Get the container that holds all the questions
        const containerElement = containerRef.current;

        // Get the top offset position of the opened question
        const questionTop = questionElement.offsetTop;

        // Scroll the container to bring the question into view
        containerElement.scrollTo({
          top: questionTop - 20, // Add some space between the question and the top of the container
          behavior: 'smooth', // Enable smooth scrolling effect
        });
      }
    }
  }, [openQuestions]); // Trigger this effect whenever the openQuestions state changes

  const toggleQuestion = (questionKey) => {
    setOpenQuestions((prevState) => ({
      ...prevState,
      [questionKey]: !prevState?.[questionKey],
    }));
  };

  return (
    <>
      <PageContainer>
        <FAQHeader>Häufig gestellte Fragen</FAQHeader>
        <Wrapper>
          <Tabs>
            {Object.keys(faqData).map((category) => (
              <TabButton
                key={category}
                $isActive={activeTab === category}
                onClick={() => {
                  setActiveTab(category);
                  setOpenQuestions({}); // Reset open question
                }}
              >
                {category}
              </TabButton>
            ))}
          </Tabs>

          <QuestionsContainer
            ref={containerRef}
            onWheel={(e) => e.stopPropagation()}
          >
            {faqData[activeTab].map((q, index) => {
              const questionKey = `${activeTab}-${index}`;
              const isOpen = !!openQuestions[questionKey]; // Check if the question is open

              return (
                <QuestionWrapper
                  key={questionKey}
                  ref={(el) => (questionRefs.current[questionKey] = el)}
                >
                  <QuestionTitle
                    $isActive={isOpen}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click event from bubbling up
                      toggleQuestion(questionKey);
                    }}
                  >
                    {q.question}
                    <IconWrapper>
                      {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </IconWrapper>
                  </QuestionTitle>
                  {isOpen && (
                    <>
                      <QuestionAnswer
                        dangerouslySetInnerHTML={{ __html: q.answer }}
                      />
                      {q.table && <QuestionAnswerTable table={q.table} />}
                      {q.references && q.references.length > 0 && (
                        <References references={q.references} />
                      )}
                    </>
                  )}
                </QuestionWrapper>
              );
            })}
          </QuestionsContainer>
        </Wrapper>
      </PageContainer>
    </>
  );
};

export default FAQ;
