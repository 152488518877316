import styled from 'styled-components';

import { sidebarWidth, collapsedWidth } from '../Sidebar/style';

export const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const MainLayout = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  min-height: calc(100vh - var(--height-banner));
  scroll: auto;
  margin-left: ${({ collapsed }) =>
    collapsed ? `${collapsedWidth}px` : `${sidebarWidth}px`};
  transition: margin-left 0.3s ease;
`;

export const Content = styled.main`
  background-color: var(--light-gray);
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: var(--height-banner);
`;
