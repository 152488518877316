import { VscTriangleUp, VscTriangleDown } from 'react-icons/vsc';
import { TiMinus } from 'react-icons/ti';
import { ReactComponent as AverageIcon } from '../../../assets/icons/average.svg';

import {
  GridItem,
  Card,
  Title,
  StyledArrow,
  RecentStatsTitle,
  RecentStatsRow,
  IconWrapperRecentStats,
  RecentStatsValue,
  RecentStatsLabel,
  MonthlyStatsTitle,
  MonthlyStatsRow,
  IconWrapperMonthlyStats,
  MonthlyStatsValue,
  MonthlyStatsLabel,
} from './style';

const SpeechRate = ({
  avrCadenceLastFiveSessions,
  avrCadenceLastFourWeeks,
  cadenceImprovement,
}) => {
  return (
    <GridItem>
      <Card>
        <Title>
          Sprechgeschwindigkeit
          <StyledArrow>
            {cadenceImprovement > 0 ? (
              <VscTriangleUp />
            ) : cadenceImprovement < 0 ? (
              <VscTriangleDown />
            ) : (
              <TiMinus />
            )}
          </StyledArrow>
        </Title>
        <RecentStatsTitle>Die letzten 5 Trainings</RecentStatsTitle>
        <RecentStatsRow>
          <IconWrapperRecentStats>
            <AverageIcon />
          </IconWrapperRecentStats>
          <RecentStatsValue>{avrCadenceLastFiveSessions}</RecentStatsValue>
          <RecentStatsLabel>
            Wörter <br /> pro Minute
          </RecentStatsLabel>
        </RecentStatsRow>

        <MonthlyStatsTitle>4-Wochen-Durchschnitt</MonthlyStatsTitle>
        <MonthlyStatsRow>
          <IconWrapperMonthlyStats>
            <AverageIcon />
          </IconWrapperMonthlyStats>
          <MonthlyStatsValue>{avrCadenceLastFourWeeks}</MonthlyStatsValue>
          <MonthlyStatsLabel>
            Wörter <br /> pro Minute
          </MonthlyStatsLabel>
        </MonthlyStatsRow>
      </Card>
    </GridItem>
  );
};

export default SpeechRate;
