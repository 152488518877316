import React from 'react';
import { VscTriangleUp, VscTriangleDown } from 'react-icons/vsc';
import { TiMinus } from 'react-icons/ti';

import {
  GridItem,
  Card,
  Title,
  RecentStatsTitle,
  RecentStatsWrapper,
  RecentStatsValue,
  RecentStyledScore,
  MonthlyStatsTitle,
  MonthlyStatsWrapper,
  MonthlyStatsValue,
  MonthlyStyledScore,
  StyledArrow,
} from './style';

const AverageScore = ({
  averageScoreLastFiveSessions,
  averageScoreLastFourWeeks,
  scoreImprovement,
}) => {
  return (
    <GridItem>
      <Card>
        <Title>
          Gesamtbewertung
          <StyledArrow>
            {scoreImprovement > 0 ? (
              <VscTriangleUp />
            ) : scoreImprovement < 0 ? (
              <VscTriangleDown />
            ) : (
              <TiMinus />
            )}
          </StyledArrow>
        </Title>

        <RecentStatsTitle>Die letzten 5 Trainings</RecentStatsTitle>
        <RecentStatsWrapper>
          <RecentStatsValue>{averageScoreLastFiveSessions}</RecentStatsValue>
          <RecentStyledScore
            value={averageScoreLastFiveSessions}
            precision={0.1}
            readOnly
          />
        </RecentStatsWrapper>
        <MonthlyStatsTitle>4-Wochen-Durchschnitt</MonthlyStatsTitle>
        <MonthlyStatsWrapper>
          <MonthlyStatsValue>{averageScoreLastFourWeeks}</MonthlyStatsValue>
          <MonthlyStyledScore
            value={averageScoreLastFourWeeks}
            precision={0.1}
            readOnly
          />
        </MonthlyStatsWrapper>
      </Card>
    </GridItem>
  );
};

export default AverageScore;
