class WebRTCManager {
  constructor(config, onMessage) {
    this.peerConnection = null;
    this.dataChannel = null;
    this.iceComplete = false;
    this.config = config;
    this.onMessage = onMessage;
    this.connectionState = null;
    this.audioTransceiver = null;
    this.videoTransceiver = null;
  }

  createDataChannel = (label = 'message') => {
    this.dataChannel = this.peerConnection.createDataChannel(label);
    this.dataChannel.onmessage = this.onMessage;
  };

  createSDPOffer = async () => {
    try {
      // Reset the PeerConnection
      if (this.peerConnection) {
        this.peerConnection.close(); // Close any existing connection
      }

      const configuration = {
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
        portRange: { min: 49152, max: 65535 },
      };
      this.peerConnection = new RTCPeerConnection(configuration); // Create a new PeerConnection

      // Create data channel
      this.createDataChannel();

      // Reserve media lines using addTransceiver
      this.audioTransceiver = this.peerConnection.addTransceiver('audio'); // Reserve audio m-line
      this.videoTransceiver = this.peerConnection.addTransceiver('video'); // Reserve video m-line

      // Create SDP offer
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer); // Set local description

      return true;
    } catch (error) {
      console.error('Error creating SDP offer:', error);
      return false;
    }
  };

  /**
   * Monitors the connection state of the peer connection and resolves when connected.
   *
   * This function attaches an event listener to the peer connection's 'connectionstatechange' event
   * and also invokes the handler immediately to check the current state. It returns a Promise that:
   * - Resolves when the connection state is 'connected'.
   * - Rejects if the connection state becomes 'failed' or 'closed', or if the connection state doesn't
   *   reach 'connected' within default 3000ms (timeoutDuration).
   *
   * @param {number} [timeoutDuration=3000] - Time in milliseconds to wait before considering the connection as timed-out.
   * @returns {Promise<void>} A promise that resolves upon a successful connection or rejects if the connection fails or times out.
   */
  trackConnection = (timeoutDuration = 3000) => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        this.peerConnection.removeEventListener(
          'connectionstatechange',
          handler
        );
        reject(new Error('Connection tracking timeout'));
      }, timeoutDuration);

      const handler = () => {
        const state = this.peerConnection.connectionState;
        if (state === 'connected') {
          clearTimeout(timeout);
          this.peerConnection.removeEventListener(
            'connectionstatechange',
            handler
          );
          resolve();
        } else if (state === 'failed' || state === 'closed') {
          clearTimeout(timeout);
          this.peerConnection.removeEventListener(
            'connectionstatechange',
            handler
          );
          reject(
            new Error('Connection failed: PeerConnection could not connect.')
          );
        }
      };

      this.peerConnection.addEventListener('connectionstatechange', handler);
      handler();
    });
  };

  /**
   * Asynchronously gathers ICE candidates from a given RTCPeerConnection.
   * Adds event listeners to monitor ICE gathering state changes and candidate discovery,
   * and resolves once the ICE gathering process is complete or the minimum number of
   * server-reflexive (srflx) candidates is found. A timeout is also set to prevent indefinite discovery.
   * We only track server-reflexive candidates because our data processing server and the client are not
   * in the same network. Therefore the server-reflexive candidates are our only option to establish a connection.
   * Usually he host candidates would be the best option for a connection but only if both peers are in the
   * same network. However, this is not the case here.
   *
   * @param {RTCPeerConnection} peerConnection - The RTCPeerConnection instance to gather ICE candidates from.
   * @param {number} [iceGatheringTimeout=5000] - Time in milliseconds to wait before considering ICE gathering as timed-out.
   * @param {number} [minSrflxCandidates=1] - Minimum number of server reflexive (srflx) candidates required before resolving early.
   * @returns {Promise<Object>} A promise that resolves to an object containing:
   *   @property {boolean} completed - Indicates whether the ICE gathering process is complete.
   *   @property {number} hostOrSrflxCandidatesFound - The number of server-reflexive ICE candidates found.
   */
  gatherIceCandidates = async (
    peerConnection,
    iceGatheringTimeout = 5000,
    minSrflxCandidates = 1
  ) => {
    return new Promise((resolve, reject) => {
      let srflxCandidatesFound = 0;
      let iceGatheringCompleted = false;
      let iceGatheringTimeoutId;

      iceGatheringTimeoutId = setTimeout(() => {
        console.warn('ICE gathering timeout reached.');
        iceGatheringCompleted = true;
        removeListeners();
        resolve({
          completed: iceGatheringCompleted,
          srflxCandidatesFound: srflxCandidatesFound,
        });
      }, iceGatheringTimeout);

      const iceGatheringStateChangeListener = () => {
        if (peerConnection.iceGatheringState === 'complete') {
          iceGatheringCompleted = true;
          removeListeners();
          resolve({
            completed: iceGatheringCompleted,
            srflxCandidatesFound: srflxCandidatesFound,
          });
        }
      };

      const iceCandidateListener = (event) => {
        if (event.candidate) {
          const candidateType = event.candidate.type;
          //console.log(`ICE candidate found: ${candidateType}`);

          if (candidateType === 'srflx') {
            srflxCandidatesFound++;
          }

          if (srflxCandidatesFound >= minSrflxCandidates) {
            removeListeners();
            clearTimeout(iceGatheringTimeoutId);
            resolve({
              completed: iceGatheringCompleted,
              hostOrSrflxCandidatesFound: srflxCandidatesFound,
            });
          }
        } else {
          iceGatheringCompleted = true;
          removeListeners();
          resolve({
            completed: iceGatheringCompleted,
            hostOrSrflxCandidatesFound: srflxCandidatesFound,
          });
        }
      };

      const removeListeners = () => {
        peerConnection.removeEventListener(
          'icegatheringstatechange',
          iceGatheringStateChangeListener
        );
        peerConnection.removeEventListener(
          'icecandidate',
          iceCandidateListener
        );
        clearTimeout(iceGatheringTimeoutId); // Ensure timeout is cleared
      };

      peerConnection.addEventListener(
        'icegatheringstatechange',
        iceGatheringStateChangeListener
      );
      peerConnection.addEventListener('icecandidate', iceCandidateListener);

      // Check initial state in case it's already complete
      if (peerConnection.iceGatheringState === 'complete') {
        iceGatheringCompleted = true;
        removeListeners();
        resolve({
          completed: iceGatheringCompleted,
          srflxCandidatesFound: srflxCandidatesFound,
        });
      }
    });
  };

  /**
   * Sends an SDP offer to the signaling server after ensuring sufficient ICE candidates have been gathered.
   *
   * This asynchronous function prepares an SDP offer using the local peer connection and waits for ICE gathering
   * to complete or until a minimum number of server reflexive (srflx) candidates are found. Once the candidate gathering
   * is complete (or timed out with insufficient candidates), it sends the SDP offer along with additional session
   * data (language, theme, audience, planned duration) to the signaling server. Upon receiving a valid SDP answer,
   * it sets the remote description on the peer connection and attempts to track the connection status. If the connection
   * state is stuck in 'connecting' for more than 3 seconds, it throws a timeout error.
   *
   * @async
   * @param {Object} data - An object containing session configuration parameters.
   * @param {string|number} data.plannedTime - The planned session time (in minutes) which is converted to seconds.
   * @param {string} data.language - The language setting for the session.
   * @param {string} data.theme - The theme setting for the session.
   * @param {string} data.audience - The audience type of the session.
   * @returns {Promise<string|null>} A promise that resolves with the session ID if a connection is successfully established,
   *                                  or null if there is an error or the connection is not properly established.
   */
  sendSDPOffer = async (data) => {
    const iceGatheringTimeout = 5000; // Adjust as needed
    const minSrflxCandidates = 5;

    // Important to wait for ICE gathering! We previously saw random connection issues on Windows and Mac (Browser independent)
    // where the connection would not be established because the ICE gathering was not complete. It got stuck afterwards in the
    // 'connecting' state and did not proceed to 'connected'. This was fixed by waiting for the ICE gathering to complete.
    // Interestingly, can wait for the 'complete' state on Mac and Windows (waiting time only a few seconds), but on Linux
    // the 'complete' state was only reached after more than a minute. Therefore, we already proceed when 5 reflexive candidates
    // are found. This is a good indicator that enough candidates are found to establish a connection.
    const { iceGatheringCompleted, srflxCandidatesFound } =
      await this.gatherIceCandidates(
        this.peerConnection,
        iceGatheringTimeout,
        minSrflxCandidates
      );

    // Check if sufficient candidates were found, or timeout occurred.
    if (!iceGatheringCompleted && srflxCandidatesFound < minSrflxCandidates) {
      console.warn(
        'ICE gathering timed out and insufficient srflx candidates found. Sending offer anyway.'
      );
      // Consider ICE restart here if you consistently encounter this situation.
    }

    const plannedDuration = parseInt(data.plannedTime, 10) * 60;
    const sdpOfferData = {
      sdp: this.peerConnection.localDescription.sdp,
      sdp_type: this.peerConnection.localDescription.type,
      iceComplete: iceGatheringCompleted,
      language: data.language,
      theme: data.theme,
      audience: data.audience,
      planned_duration: plannedDuration,
    };

    try {
      const response = await fetch(`${this.config.API_URL}/sdp_offer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(sdpOfferData),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (
          responseData.sdp_answer_type === 'answer' &&
          this.peerConnection != null
        ) {
          await this.setRemoteDescription(responseData.sdp_answer);
          try {
            await this.trackConnection();
            return responseData.session_id;
          } catch (e) {
            console.error('Connection track failed', e);
            return null;
          }
        } else {
          console.info('Not connecting, user aborted by going back.');
          return null;
        }
      } else {
        console.error('Failed to send offer');
        return null;
      }
    } catch (error) {
      console.error('Error sending SDP offer:', error);
      return null;
    }
  };

  setRemoteDescription = async (sdpAnswer) => {
    const remoteDescription = new RTCSessionDescription({
      type: 'answer',
      sdp: sdpAnswer,
    });
    await this.peerConnection.setRemoteDescription(remoteDescription);
  };

  addTrack = (track, stream) => {
    this.peerConnection.addTrack(track, stream);
  };

  replaceTrack = (kind, track) => {
    let transceiver;
    if (kind === 'audio') {
      transceiver = this.audioTransceiver;
    } else if (kind === 'video') {
      transceiver = this.videoTransceiver;
    }

    if (transceiver && transceiver.sender) {
      transceiver.sender.replaceTrack(track);
    } else {
      console.warn(`No transceiver or sender found for ${kind}.`);
    }
  };

  sendMessage = (msg) => {
    if (this.dataChannel?.readyState === 'open') {
      this.dataChannel.send(msg);
    } else {
      console.warn('Data channel is not open yet.');
    }
  };

  close = () => {
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }
  };
}

export default WebRTCManager;
