import React, { useState, useEffect, useRef, useCallback } from 'react';
import VideocamOff from '@mui/icons-material/VideocamOff';
import MicOffIcon from '@mui/icons-material/MicOff';

import { Group, ButtonGroup, Container, Label } from '../../style';
import { AudioVisualization, VideoPreview } from './style';
import Button from '../../../../components/Button/Button';

const DeviceSetup = ({
  onStart,
  onBack,
  mediaStream,
  setMediaStream,
  connectionStatus,
  selectedCamera,
  setSelectedCamera,
  selectedMicrophone,
  setSelectedMicrophone,
}) => {
  const [cameras, setCameras] = useState([]);
  const [microphones, setMicrophones] = useState([]);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const isFetchingDevices = useRef(false);
  const permissionRequested = useRef(false); // Persist across renders

  // Enumerate available devices on component mount
  useEffect(() => {
    async function fetchDevices() {
      if (isFetchingDevices.current) {
        return;
      }
      isFetchingDevices.current = true; // Mark as fetching
      try {
        let devices = await navigator.mediaDevices.enumerateDevices();

        // Check if devices have labels (names)
        const hasDeviceNames = devices.some((device) => device.label);

        // Check browser permissions for camera and mic before requesting access
        const cameraPerm = await navigator.permissions.query({
          name: 'camera',
        });
        const micPerm = await navigator.permissions.query({
          name: 'microphone',
        });
        const hasMediaPermissions =
          cameraPerm.state === 'granted' && micPerm.state === 'granted';

        // Request permissions **only if needed** (no names & no prior permission)
        if (
          (!hasDeviceNames || !hasMediaPermissions) &&
          !permissionRequested.current
        ) {
          permissionRequested.current = true;

          // Request permission for audio and video without starting the stream
          const tempStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });

          // Immediately stop the media tracks to prevent camera activation
          tempStream.getTracks().forEach((track) => track.stop());

          // Fetch the devices again, now with labels
          devices = await navigator.mediaDevices.enumerateDevices();
        }

        // Update state with available cameras and microphones
        setCameras(devices.filter((device) => device.kind === 'videoinput'));
        setMicrophones(
          devices.filter((device) => device.kind === 'audioinput')
        );
      } catch (error) {
        console.error('Error accessing media devices:', error);
        setError('Failed to access media devices. Please check permissions.');
      }
    }

    // Listen for device changes (e.g., plugging in a new webcam)
    navigator.mediaDevices.addEventListener('devicechange', fetchDevices);

    // Initial device fetch on component mount
    fetchDevices();

    // Listen for device changes (e.g., plugging in a webcam)
    const handleDeviceChange = () => {
      fetchDevices();
    };
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    // Cleanup event listener on component unmount
    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
    };
  }, []);

  // When the user selects a camera
  const handleCameraChange = (e) => {
    setSelectedCamera(e.target.value);
    sessionStorage.setItem('presada:selectedCamera', e.target.value);
  };

  // When the user selects a microphone
  const handleMicrophoneChange = (e) => {
    setSelectedMicrophone(e.target.value);
    sessionStorage.setItem('presada:selectedMicrophone', e.target.value);
  };

  // Function to stop all tracks in a media stream
  const stopMediaStream = (stream) => {
    if (stream) {
      // Stop all tracks
      stream.getTracks().forEach((track) => {
        track.stop();
      });

      // Explicitly set the video element source to null
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const handleBack = () => {
    stopMediaStream(mediaStream); // Stop the media stream
    onBack(); // Call the parent handler
  };

  // Update the media stream whenever selected devices change
  const updateMediaStream = useCallback(async () => {
    try {
      setError(null); // Clear previous errors

      // Create constraints for the selected devices
      const constraints = {
        video: selectedCamera ? { deviceId: { exact: selectedCamera } } : false,
        audio: selectedMicrophone
          ? { deviceId: { exact: selectedMicrophone } }
          : false,
      };

      // Request a new media stream
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);

      setMediaStream((prevStream) => {
        if (prevStream && prevStream.active) {
          prevStream.getTracks().forEach((track) => {
            track.stop(); // Stop only old tracks
          });
        }

        return newStream;
      });

      // Update the video element with the new stream
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
      }

      // If a microphone is selected, visualize the audio
      if (selectedMicrophone) {
        visualizeAudio(newStream);
      }
    } catch (err) {
      console.error('Error updating media stream:', err);
      setError(
        'Failed to update media stream. Please check your device selection.'
      );
    }
  }, [selectedCamera, selectedMicrophone, setMediaStream]);

  // Visualize audio amplitude for the selected microphone
  const visualizeAudio = (stream) => {
    const audioTracks = stream.getAudioTracks();
    if (!audioTracks.length) return;

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 256;

    source.connect(analyzer);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      analyzer.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const numBars = 20;
      const barWidth = canvas.width / numBars;
      const maxAmplitude = Math.max(...dataArray);
      const activeBars = Math.ceil((maxAmplitude / 255) * numBars);

      for (let i = 0; i < numBars; i++) {
        const isActive = i < activeBars;
        ctx.fillStyle = isActive ? 'turquoise' : '#ccc';
        ctx.fillRect(
          i * barWidth,
          canvas.height / 2,
          barWidth - 2,
          -canvas.height / 4
        );
      }

      requestAnimationFrame(draw);
    };

    draw();
  };

  // Trigger update when selected devices change
  useEffect(() => {
    if (selectedCamera || selectedMicrophone) {
      updateMediaStream();
    }
  }, [selectedCamera, selectedMicrophone, updateMediaStream]);

  return (
    <Container>
      <h2>Aufnahmeeinstellung</h2>

      {error && <p className="error">{error}</p>}

      <Group>
        <Label>Kamera:</Label>
        <select value={selectedCamera} onChange={handleCameraChange}>
          <option value="">Keine Kamera</option>
          {cameras.map((camera) => (
            <option key={camera.deviceId} value={camera.deviceId}>
              {camera.label || `Kamera ${camera.deviceId}`}
            </option>
          ))}
        </select>
      </Group>

      <VideoPreview>
        {selectedCamera ? (
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            style={{ width: '100%' }}
          />
        ) : (
          <div>
            <VideocamOff fontSize="large" color="disabled" />
            <p>Ohne Kamera üben.</p>
          </div>
        )}
      </VideoPreview>

      <Group>
        <Label>Mikrofon:</Label>
        <select value={selectedMicrophone} onChange={handleMicrophoneChange}>
          <option value="">Kein Mikrofon</option>
          {microphones.map((microphone) => (
            <option key={microphone.deviceId} value={microphone.deviceId}>
              {microphone.label || `Mikrofon ${microphone.deviceId}`}
            </option>
          ))}
        </select>
      </Group>

      {selectedMicrophone ? (
        <AudioVisualization>
          <canvas ref={canvasRef} width="300" height="100" />
        </AudioVisualization>
      ) : (
        <div>
          <MicOffIcon fontSize="large" color="disabled" />
          <p>Kein Mikrofon ausgewählt!</p>
        </div>
      )}

      <ButtonGroup>
        <Button type="button" onClick={handleBack}>
          Zurück
        </Button>

        {
          /* Disable the button if no mic is selected */
          !selectedMicrophone ? (
            <p> Mikrofon muss ausgewählt werden!</p>
          ) : /* Only enable button if connection state is 'connected' */
          connectionStatus === 'connected' ? (
            /* Enable the button if a mic is selected */
            <Button type="button" onClick={onStart}>
              Weiter
            </Button>
          ) : (
            <p> Verbindung wird hergestellt...</p>
          )
        }
      </ButtonGroup>
    </Container>
  );
};

export default DeviceSetup;
