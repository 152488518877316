import React from 'react';

import { MessageContainer, HighlightedText } from './style';

const ServerErrorMessage = () => {
  return (
    <MessageContainer>
      Entschuldigen Sie bitte den internen Fehler. <br />
      Wenden Sie sich bitte direkt an{' '}
      <HighlightedText>info@presada.ai</HighlightedText>
    </MessageContainer>
  );
};

export default ServerErrorMessage;
