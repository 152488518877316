import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.6;
  font-size: 1rem;
  padding: 1rem;
  max-width: 80%; /* This allows it to scale better on small screens while keeping a structured size on desktops. */
  width: 50rem; /* Fallback for larger screens - equivalent to 800px */
  margin: 0 auto;
  height: 300px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  gap: 4px;
`;

export const HighlightedText = styled.span`
  background-color: var(--turquoise);
  padding: 2px 6px;
  border-radius: 8px;
  font-weight: bold;
  color: var(--white);
`;
