import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import PrivateRoute from './components/PrivateRoute';
import PrivateAdminRoute from './components/PrivateAdminRoute';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Welcome from './pages/Welcome';
import Training from './pages/Training/Training';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings';
import FAQ from './pages/FAQ/FAQ';

function App() {
  return (
    <Router
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Protected Routes */}
        <Route
          path="/welcome"
          element={
            <PrivateRoute>
              <Layout>
                <Welcome />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/training"
          element={
            <PrivateRoute>
              <Layout>
                <Training />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/evaluation_history"
          element={
            <PrivateRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <Layout>
                <FAQ />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateAdminRoute>
              <Layout>
                <Settings />
              </Layout>
            </PrivateAdminRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
