// style.js
import styled from 'styled-components';

export const ImageAndArrowsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1.5rem; /* optional spacing */
`;

export const ArrowPlaceholder = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RecordingImageContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RecordingImage = styled.div`
  img {
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const VideoFeed = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border: 2px solid var(--turquoise);
  border-radius: var(--border-radius);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Timer = styled.div`
  margin-bottom: 16px;
  h2 {
    font-size: 1.5rem;
    margin: 0;
  }
`;

export const StyledVideo = styled.video`
  width: 100%;
`;
