import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { BaseButton } from '../Button/style';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    font-family: 'Hanken Grotesk', sans-serif;
  }

  & .MuiDialogTitle-root {
    font-weight: bold;
    text-align: center;
  }

  & .MuiDialogContentText-root {
    font-size: 0.8rem;
    text-align: center;
  }

  & .MuiDialogActions-root {
    display: flex;
    justify-content: space-around;
  }
`;

export const DialogButton = styled(BaseButton)`
  background-color: var(--white);
  color: ${({ $isCancel }) => ($isCancel ? 'var(--red)' : 'var(--black)')};
  border: ${({ $isCancel }) =>
    $isCancel ? '1px solid var(--red)' : '1px solid var(--turquoise)'};
  &:hover {
    background-color: ${({ $isCancel }) =>
      $isCancel ? 'var(--red)' : 'var(--turquoise)'};
    color: var(--white);
  }
`;
