import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import {
  Card,
  TitleContainer,
  Title,
  Subtitle,
  ChartWrapper,
  GridItem,
} from './style';

// Format timestamp to display date and time
const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp.replace(/_/g, 'T'));
  return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
    .toString()
    .padStart(
      2,
      '0'
    )}\n${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
};

// Custom X-Axis Tick component
const renderCustomXAxisTick = ({ x, y, payload }) => {
  // Ensure that the tick value exists before rendering to prevent errors.
  // Recharts may sometimes pass `undefined` or `null` as tick values,
  // especially if the chart resizes or auto-skips labels due to limited space.
  // Returning `null` prevents rendering issues when no valid value is available.
  if (!payload.value) return null;

  const [date, time] = payload.value.split(' ');

  return (
    <foreignObject x={x - 24} y={y - 2} width="50" height="30">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '12px',
          color: 'var(--black)',
        }}
      >
        <span>{date}</span>
        <span>{time}</span>
      </div>
    </foreignObject>
  );
};

// Custom Y-Axis Tick component
const renderCustomYAxisTick = ({ x, y, payload }) => {
  if (!payload.value) return null;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={12} y={2} fontSize="12px" textAnchor="end">
        {payload.value}
      </text>
      <foreignObject x={16} y={-14} width="20" height="20">
        <StarIcon style={{ fontSize: '20px', color: 'var(--turquoise)' }} />
      </foreignObject>
    </g>
  );
};

const OverallTrainingProgress = ({
  scoresLastFiveSessions = [],
  timestampsLastFiveSessions = [],
}) => {
  const chartData = scoresLastFiveSessions.map((score, index) => ({
    id: index + 1,
    score: score,
    timestamp: formatTimestamp(timestampsLastFiveSessions[index]),
  }));

  return (
    <GridItem>
      <Card>
        <TitleContainer>
          <Title>Entwicklung</Title>
          <Subtitle>der letzten 5 Trainings</Subtitle>
        </TitleContainer>
        <ChartWrapper>
          <ResponsiveContainer width="100%" height={190}>
            <LineChart
              data={chartData}
              margin={{ top: 12, right: 20, left: 10, bottom: 20 }}
            >
              {/* CartesianGrid is not visible, maybe beacause of the size  */}
              <CartesianGrid stroke="#999" strokeDasharray="5 5" opacity={1} />
              <XAxis
                dataKey="timestamp"
                tick={renderCustomXAxisTick}
                padding={{ left: 20, right: 20 }}
              />

              <YAxis
                domain={[1, 5]}
                tick={renderCustomYAxisTick}
                orientation="right"
                axisLine={false}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="score"
                stroke="var(--turquoise)"
                dot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </ChartWrapper>
      </Card>
    </GridItem>
  );
};

export default OverallTrainingProgress;
