import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  max-width: 80%; /* This allows it to scale better on small screens while keeping a structured size on desktops. */
  width: 50rem; /* Fallback for larger screens - equivalent to 800px */
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  position: relative;
  height: 300px;
`;

export const Loader = styled.div`
  width: 100px;
  height: 100px;
  margin: 2em;
  border-radius: 50%;
  border: 8px solid transparent;
  border-top-color: var(--turquoise);
  border-bottom-color: var(--turquoise);
  animation: ${spin} 1s linear infinite;
  display: inline-block;
`;
