import {
  PiSmileyLight,
  PiSmileyMehLight,
  PiSmileySadLight,
} from 'react-icons/pi';

export const evaluationStyles = {
  best: { color: 'var(--best)', icon: PiSmileyLight },
  neutral: { color: 'var(--neutral)', icon: PiSmileyMehLight },
  worst: { color: 'var(--worst)', icon: PiSmileySadLight },
};

export const getStyledSmileIcon = (evaluation) => {
  const { color, icon: Icon } =
    evaluationStyles[evaluation] || evaluationStyles.neutral;
  return (
    <Icon
      style={{
        fill: color,
        stroke: color,
        strokeWidth: 2,
      }}
    />
  );
};
