import React, { useState } from 'react';
import { subDays, format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

import {
  GridItem,
  Card,
  TitleContainer,
  Title,
  Subtitle,
  ChartWrapper,
  TooltipContainer,
  XAxisTickContainer,
  XAxisTickMonth,
} from './style';

// Transform JSON data into a format suitable for the chart
const processTrainingData = (trainingTimesLastFourWeeks) => {
  const today = new Date();
  const chartData = [];

  const formatMonth = (date) => {
    const monthMap = {
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mär',
      Apr: 'Apr',
      May: 'Mai',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Okt',
      Nov: 'Nov',
      Dec: 'Dez',
    };

    let formattedMonth = format(date, 'MMM', { locale: de });

    return monthMap[formattedMonth] || formattedMonth;
  };

  // Initialize an array containing the last 28 days, including today,
  // and set the number of training sessions to 0 by default.
  for (let i = 0; i < 28; i++) {
    const currentDate = subDays(today, 27 - i);
    const formattedMonth = formatMonth(currentDate);

    chartData.push({
      date: format(currentDate, 'yyyy-MM-dd'), // Format the date for searching in JSON
      label: `${format(currentDate, 'd')} ${formattedMonth}`, // Format the label for the X-axis
      sessions: 0, // Default to 0 training sessions
    });
  }

  // Iterate through the training data from the last four weeks
  trainingTimesLastFourWeeks.forEach(([date, count]) => {
    const formattedDate = format(parseISO(date), 'yyyy-MM-dd'); // Convert the date to 'yyyy-MM-dd' format
    const dataEntry = chartData.find((entry) => entry.date === formattedDate); // Find the corresponding entry in chartData
    if (dataEntry) {
      dataEntry.sessions = count; // Update the number of training sessions for the matching date
    }
  });

  return chartData;
};

// Custom Tooltip component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <p>{`Datum: ${payload[0].payload.label}, Trainings: ${payload[0].value}`}</p>
      </TooltipContainer>
    );
  }
  return null;
};

const TrainingActivity = ({ trainingTimesLastFourWeeks }) => {
  const [focusBar, setFocusBar] = useState(null);
  const chartData = processTrainingData(trainingTimesLastFourWeeks);

  return (
    <GridItem>
      <Card>
        <TitleContainer>
          <Title>Aktivität</Title>
          <Subtitle>letzte 4 Wochen</Subtitle>
        </TitleContainer>
        <ChartWrapper>
          <ResponsiveContainer width="100%" height={190}>
            <BarChart
              data={chartData}
              margin={{ top: 16, right: 20, left: -24, bottom: 8 }}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setFocusBar(state.activeTooltipIndex); // Highlight the bar when hovering over it
                } else {
                  setFocusBar(null); // Remove highlight when the cursor leaves the chart
                }
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="label"
                tickLine={false}
                interval={0}
                ticks={chartData.map((d) => d.label)}
                tick={({ x, y, payload }) => {
                  const [day, month] = payload.value.split(' ');
                  return (
                    <foreignObject x={x - 15} y={y - 3} width="30" height="30">
                      <XAxisTickContainer>
                        <span>{day}</span>
                        <XAxisTickMonth>{month}</XAxisTickMonth>
                      </XAxisTickContainer>
                    </foreignObject>
                  );
                }}
              />
              <YAxis
                allowDecimals={false}
                tick={{ fontSize: '12px', fill: 'var(--dark-gray)' }}
                tickLine={false}
                domain={[0, 'dataMax']} // Sets the Y-axis range dynamically from 0 to the maximum value in the dataset.
                ticks={Array.from(
                  { length: Math.max(...chartData.map((d) => d.sessions)) + 1 },
                  (_, i) => i
                )}
              />
              <Tooltip
                cursor={false}
                content={<CustomTooltip />}
                // Ensure the tooltip does not appear when hovering over bars with zero sessions
                // TODO: but appearence must be improved
                // wrapperStyle={{ display: focusBar !== null ? 'block' : 'none' }}
              />
              <Bar dataKey="sessions" barSize={16} radius={[4, 4, 0, 0]}>
                {chartData.map((entry, index) => (
                  <Cell
                    // Change the bar color when hovering over an active bar
                    fill={
                      focusBar === index
                        ? 'var(--turquoise)' // Highlighted color when hovered
                        : // '#CAEDED' // Alternative color instead of gray for better visibility
                          'var(--mid-gray)' // Default color
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </ChartWrapper>
      </Card>
    </GridItem>
  );
};

export default TrainingActivity;
