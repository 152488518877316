// src/pages/Welcome.js
import React from 'react';
import '../styles/Welcome.css';
import { AiOutlinePlus, AiOutlineQuestionCircle } from 'react-icons/ai';
import { MdOutlineAnalytics } from 'react-icons/md';

import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  const navigateToTraining = () => {
    navigate('/training');
  };

  const navigateToFAQ = () => {
    navigate('/faq');
  };

  const navigateToDashboard = () => {
    navigate('/evaluation_history');
  };

  return (
    <div className="welcome-page">
      <h1 className="welcome-text">Willkommen!</h1>
      <div className="welcome-page-buttons-container">
        <div className="training-button" onClick={navigateToTraining}>
          <AiOutlinePlus size={40} className="plus-icon" />
          <span>Training</span>
        </div>
        <div className="analytics-button" onClick={navigateToDashboard}>
          <MdOutlineAnalytics size={40} className="analytics-icon" />
          <span>Erfolge</span>
        </div>
        <div className="faq-button" onClick={navigateToFAQ}>
          <AiOutlineQuestionCircle size={40} className="question-icon" />
          <span>FAQ</span>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
