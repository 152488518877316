import { VscTriangleUp, VscTriangleDown } from 'react-icons/vsc';
import { TiMinus } from 'react-icons/ti';

import {
  GridItem,
  Card,
  Title,
  StyledArrow,
  RecentStatsTitle,
  RecentStatsRow,
  RecentStatsValue,
  RecentStatsLabel,
  MonthlyStatsTitle,
  MonthlyStatsRow,
  MonthlyStatsValue,
  MonthlyStatsLabel,
} from './style';

const TimeDeviation = ({
  durDevLastFiveSessions,
  durDevLastFourWeeks,
  durationImprovement,
}) => {
  return (
    <GridItem>
      <Card>
        <Title>
          Dauer
          <StyledArrow>
            {durationImprovement > 0 ? (
              <VscTriangleUp />
            ) : durationImprovement < 0 ? (
              <VscTriangleDown />
            ) : (
              <TiMinus />
            )}
          </StyledArrow>
        </Title>
        <RecentStatsTitle>Die letzten 5 Trainings</RecentStatsTitle>
        <RecentStatsRow>
          <RecentStatsValue>{durDevLastFiveSessions}%</RecentStatsValue>
          <RecentStatsLabel>
            der geplanten <br /> Dauer
          </RecentStatsLabel>
        </RecentStatsRow>

        <MonthlyStatsTitle>4-Wochen-Durchschnitt</MonthlyStatsTitle>
        <MonthlyStatsRow>
          <MonthlyStatsValue>{durDevLastFourWeeks}%</MonthlyStatsValue>
          <MonthlyStatsLabel>
            der geplanten <br /> Dauer
          </MonthlyStatsLabel>
        </MonthlyStatsRow>
      </Card>
    </GridItem>
  );
};

export default TimeDeviation;
