import { useForm, Controller } from 'react-hook-form';
import {
  Input,
  OptionGroup,
  Option,
  SliderContainer,
  Slider,
  SliderValue,
  themeContainerStyles,
  themeImageStyles,
  FormContainer,
} from './style';

import { Group, ButtonGroup, Label } from '../../style';

import Button from '../../../../components/Button/Button';

import tourData from '../../../../assets/data/tourThemes.json';

const tourThemes = Object.entries(tourData).map(([key, value]) => ({
  id: key,
  name: value.name,
  image: require(`../../../../assets/themes/${key}.png`),
}));

const TrainingSettings = ({ onExerciseNow, handleBack }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      language: 'de',
      theme: tourThemes[0]?.id || '',
      audience: 'mixed',
      plannedTime: 5,
    },
  });

  const onSubmit = (data) => {
    onExerciseNow(data);
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <h2>Trainingsoptionen</h2>

      <Group>
        <Label>Sprache</Label>
        <Controller
          control={control}
          name="language"
          render={({ field: { value, onChange } }) => (
            <OptionGroup>
              <Option selected={value === 'de'} onClick={() => onChange('de')}>
                Deutsch
              </Option>
              <Option selected={value === 'en'} onClick={() => onChange('en')}>
                Englisch
              </Option>
            </OptionGroup>
          )}
        />
      </Group>

      <Group>
        <Label>Thema</Label>
        <Controller
          control={control}
          name="theme"
          render={({ field: { onChange, value } }) => {
            const selectedTheme = tourThemes.find(
              (theme) => theme.id === value
            );
            return (
              <div style={themeContainerStyles}>
                <Input as="select" value={value} onChange={onChange}>
                  {tourThemes.map((theme) => (
                    <option key={theme.id} value={theme.id}>
                      {theme.name}
                    </option>
                  ))}
                </Input>
                <img
                  src={selectedTheme?.image}
                  alt="Theme preview"
                  style={themeImageStyles}
                />
              </div>
            );
          }}
        />
      </Group>

      <Group>
        <Label>Zielgruppe</Label>
        <Controller
          control={control}
          name="audience"
          render={({ field: { value, onChange } }) => (
            <OptionGroup>
              <Option
                selected={value === 'mixed'}
                onClick={() => onChange('mixed')}
              >
                Gemischte Sammelführung
              </Option>
              <Option
                selected={value === 'experts'}
                onClick={() => onChange('experts')}
              >
                Experten
              </Option>
              <Option
                selected={value === 'simpleLanguage'}
                onClick={() => onChange('simpleLanguage')}
              >
                Einfache Sprache
              </Option>
            </OptionGroup>
          )}
        />
      </Group>

      <Group>
        <Label>Geplante Zeit</Label>
        <Controller
          control={control}
          name="plannedTime"
          render={({ field: { value, onChange } }) => (
            <SliderContainer>
              <Slider
                type="range"
                min="1"
                max="90"
                step="1"
                value={value || 1}
                onChange={(e) => onChange(parseInt(e.target.value, 10))}
              />
              <SliderValue>
                {String(value || 1).padStart(2, ' ')} Minuten
              </SliderValue>
            </SliderContainer>
          )}
        />
      </Group>

      <ButtonGroup>
        <Button type="button" onClick={handleBack}>
          Zurück
        </Button>
        <Button type="submit">Weiter</Button>
      </ButtonGroup>
    </FormContainer>
  );
};

export default TrainingSettings;
