import React from 'react';
import { LoaderContainer } from '../LoadingWheel/style';
import { CountdownText } from './style';

const Countdown = ({ count }) => {
  return (
    <LoaderContainer>
      <CountdownText>{count - 1 > 0 ? count - 1 : 'Start...'}</CountdownText>
    </LoaderContainer>
  );
};

export default Countdown;
