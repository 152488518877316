import styled from 'styled-components';
import Rating from '@mui/material/Rating';

export const OverallScoreCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  background: var(--white);
  padding-top: 8px;
  padding-left: 24px;
  border-radius: 4px;
  box-shadow: var(--shadow-default);
`;

export const OverallScoreTitle = styled.h3`
  font-size: 1.2rem;
  color: var(--black);
`;

export const RecentStatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 4px;
`;

export const RecentStatsValue = styled.p`
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  max-width: 100%;
  white-space: nowrap;
`;

export const RecentStyledScore = styled(Rating)`
  /*
   Using "&&" increases CSS specificity to override MUI's default styles.
   MUI applies styles with high specificity, so without "&&", our styles
   might get overridden by the built-in ones. "&&" duplicates the selector,
   making it more specific and ensuring our styles take priority.
  */
  && {
    color: var(--turquoise);
    font-size: 2.5rem;
    margin-bottom: 4px;
  }
`;
