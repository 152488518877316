import styled from 'styled-components';

export const sidebarWidth = 240;
export const collapsedWidth = 80;

export const SidebarWrapper = styled.div`
  position: fixed;
  top: var(--height-banner);
  left: 0;
  height: calc(100vh - var(--height-banner));
  width: ${({ collapsed }) =>
    collapsed ? `${collapsedWidth}px` : `${sidebarWidth}px`};
  background-color: var(--white);
  box-shadow: var(--shadow-default);
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-end')};
  padding-right: ${({ collapsed }) => (collapsed ? '0' : '2rem')};
`;

export const ToggleButton = styled.div`
  cursor: pointer;
  display: ${({ collapsed }) => (collapsed ? 'flex' : 'none')};

  &:hover {
    color: var(--turquoise);
  }
`;

export const CollapseButton = styled.div`
  cursor: pointer;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};

  &:hover {
    color: var(--turquoise);
  }
`;

export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  margin-top: 0.2rem;
`;

export const MenuItem = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--black);
  background-color: var(--white);
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
  gap: ${({ collapsed }) => (collapsed ? '0' : '0.6rem')};
  &:hover {
    background-color: var(--light-gray);
  }

  svg {
    color: var(--turquoise);
  }
`;

export const MenuIconWrapper = styled.div`
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.2rem;
    color: var(--turquoise);
  }
`;
