import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: var(--height-without-banner);
  overflow-y: auto;
`;

export const FAQHeader = styled.h1`
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 800px;
  gap: 10px;
  margin-bottom: 0.4rem;
`;

export const TabButton = styled.button`
  all: unset; /* Reset all styles */
  font-size: 1rem;
  font-weight: 350;
  padding: 0.5rem 1rem;
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--turquoise)' : 'var(--white)'};
  color: ${({ $isActive }) => ($isActive ? 'var(--white)' : 'var(--black)')};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: var(--shadow-default);
  display: inline-block; /* Make the button inline */

  &:hover {
    color: var(--white);
    box-shadow: var(--shadow-default);
  }
`;

export const QuestionsContainer = styled.div`
  width: 800px;
  max-height: 750px; /* Limit the height of the container */
  margin: 1rem 0;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  position: relative;
  overflow-y: auto; /* Add a scrollbar when the content is too long */
  scroll-behavior: smooth;
`;

export const QuestionWrapper = styled.div`
  padding: 4px 0;
`;

export const QuestionTitle = styled.p`
  font-size: 1rem;
  font-weight: 350;
  cursor: pointer;
  padding: 10px;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
  line-height: 150%;
  border-radius: 4px;
  background-color: ${({ isActive }) =>
    isActive ? '#EDEEF1' : 'var(--light-gray)'};
  color: ${({ isActive }) => (isActive ? 'var(--black)' : '#575757')};
  &:hover {
    color: var(--black);
    box-shadow: var(--shadow-default);
  }
`;

export const QuestionAnswer = styled.p`
  font-size: 0.9rem;
  font-weight: 350;
  padding: 10px;
  margin: 0;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
  color: var(--dark-gray);
  line-height: 150%;
`;

export const ReferencesContainer = styled.p`
  font-size: 0.7rem;
  font-weight: 350;
  padding: 10px;
  margin: 0;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
  color: var(--dark-gray);
  line-height: 150%;

  a {
    color: var(--black);
    text-decoration: none;
  }

  a:hover {
    color: var(--black);
    text-decoration: underline;
    font-weight: bold;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px auto;

  table {
    width: 60%;
    border-collapse: collapse;
  }
  th {
    font-weight: bold;
    padding: 8px;
    text-align: left;
    border-bottom: 2px solid black;
  }
  td {
    padding: 8px;
    border-bottom: 1px solid var(--mid-gray);
  }
  tr:last-child td {
    border-bottom: none;
  }
  td:nth-child(2) {
    text-align: center;
  }
`;

export const IconWrapper = styled.span`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;
