import React from 'react';
import {
  GridItem,
  Card,
  Title,
  RecentStatsTitle,
  RecentStatsRow,
  RecentStatsValue,
  RecentStatsLabel,
  MonthlyStatsTitle,
  MonthlyStatsRow,
  MonthlyStatsValue,
  MonthlyStatsLabel,
} from './style';

const LanguageStats = ({
  germanTrainingsLastFiveSessions,
  engTrainingsLastFiveSessions,
  germanTrainingsPercentage,
  englishTrainingsPercentage,
}) => {
  return (
    <GridItem>
      <Card>
        <Title>Sprache</Title>

        <RecentStatsTitle>Die letzten 5 Trainings</RecentStatsTitle>
        <RecentStatsRow>
          <RecentStatsValue>{germanTrainingsLastFiveSessions}</RecentStatsValue>
          <RecentStatsLabel>
            {germanTrainingsLastFiveSessions === 1
              ? 'Training auf Deutsch'
              : 'Trainings auf Deutsch'}
          </RecentStatsLabel>
        </RecentStatsRow>
        <RecentStatsRow>
          <RecentStatsValue>{engTrainingsLastFiveSessions}</RecentStatsValue>
          <RecentStatsLabel>
            {engTrainingsLastFiveSessions === 1
              ? 'Training auf Englisch'
              : 'Trainings auf Englisch'}
          </RecentStatsLabel>
        </RecentStatsRow>

        <MonthlyStatsTitle>4-Wochen-Durchschnitt</MonthlyStatsTitle>
        <MonthlyStatsRow>
          <MonthlyStatsValue>{germanTrainingsPercentage}%</MonthlyStatsValue>
          <MonthlyStatsLabel>Trainings auf Deutsch</MonthlyStatsLabel>
        </MonthlyStatsRow>
        <MonthlyStatsRow>
          <MonthlyStatsValue>{englishTrainingsPercentage}%</MonthlyStatsValue>
          <MonthlyStatsLabel>Trainings auf Englisch</MonthlyStatsLabel>
        </MonthlyStatsRow>
      </Card>
    </GridItem>
  );
};

export default LanguageStats;
