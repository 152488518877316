import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 1400px;
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    max-width: 90%;
  }

  @media screen and (max-width: 768px) {
    max-width: 95%;
    padding: 1vh 4vw 8vh;
`;

export const DashboardHeader = styled.h1`
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  max-width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 12px;
  margin-bottom: 1rem;
  max-width: 100%;
  position: relative;
`;

export const TabButton = styled.button`
  all: unset; /* Reset all styles */
  font-size: 0.8rem;
  font-weight: 350;
  padding: 0.5rem 1rem;
  background-color: ${({ $isActive, disabled }) =>
    disabled ? '#ccc' : $isActive ? 'var(--turquoise)' : 'var(--white)'};
  color: ${({ $isActive, disabled }) =>
    disabled ? 'var(--white)' : $isActive ? 'var(--white)' : 'var(--black)'};
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: all 0.3s ease-in-out;
  box-shadow: var(--shadow-default);
  display: inline-block; /* Make the button inline */

  &:hover {
    background-color: ${({ disabled, $isActive }) =>
      disabled ? '#ccc' : $isActive ? 'var(--turquoise)' : '#279E9D'};
    color: ${({ disabled, $isActive }) =>
      disabled ? 'var(--white)' : $isActive ? 'var(--white)' : 'var(--white)'};
    box-shadow: ${({ disabled }) =>
      disabled ? 'none' : 'var(--shadow-default)'};
  }

  /* Tooltip for disabled Button */
  &:hover::after {
    content: ${({ disabled }) => (disabled ? '"zu wenig Trainings"' : '""')};
    position: absolute;
    bottom: -32px;
    left: 90%;
    transform: translateX(-50%);
    background: #575757;
    color: var(--white);
    font-size: 0.7rem;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: ${({ disabled }) => (disabled ? '1' : '0')};
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }
`;

export const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 24px;
  align-items: start;
  margin-top: 0.5rem;
  max-width: 100%;
  width: 100%;
`;

export const UserErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.6;
  font-size: 1rem;
  padding: 1rem;
  width: 800px;
  margin: 0 auto;
  height: 300px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  position: relative;
  gap: 4px;
  }
`;

export const HighlightedText = styled.span`
  background-color: var(--turquoise);
  padding: 2px 6px;
  border-radius: 8px;
  font-weight: bold;
  color: var(--white);
`;
