import styled from 'styled-components';

export const AudioVisualization = styled.div`
  background-color: var(--white);
  border: 1px solid var(--mid-gray);
  border-radius: var(--border-radius);
  display: block;
  margin: 10px auto;
`;

/* Video Preview */
export const VideoPreview = styled.div`
  margin: 20px 0;
`;
