import styled from 'styled-components';

export const BaseButton = styled.button`
  all: unset;
  font-size: 1rem;
  font-weight: 350;
  padding: 0.5rem 1rem;
  background-color: var(--white);
  color: var(--black);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: var(--shadow-default);
  display: inline-block;

  &:hover {
    background-color: var(--turquoise);
    color: var(--white);
  }
`;
