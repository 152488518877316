// src/pages/Login.js
import React from 'react';
import { useState } from 'react';

import Banner from '../components/Banner';
import config from '../config/config';

import './Login.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const handleEmailSubmit = async () => {
    setStatusMessage('');
    try {
      // do not await response - just tell the user that the email was sent
      fetch(`${config.API_URL}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
        credentials: 'include', // Include cookies
      });

      // no matter what server responds, we show the same message thus that
      // a potential attacker can't use this endpoint to determine if an email
      // is registered or not
      setStatusMessage(
        'Bitte überprüfen Sie Ihre E-Mails für weitere Anweisungen.'
      );
    } catch (error) {
      setStatusMessage('Fehler beim Zurücksetzen des Passworts.');
      console.error('Password reset error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    handleEmailSubmit();
  };

  return (
    <div className="login-page">
      <Banner />
      <div className="login-content">
        <div className="tagline">
          <p>Your</p>
          <p>
            personal <span className="highlight turquoise">AI</span>
          </p>
          <p>
            <span className="highlight turquoise">communication</span>
          </p>
          <p>coach</p>
        </div>
        <div className="login-form-container">
          <form onSubmit={handleSubmit} className="login-form">
            <label htmlFor="username">Email:</label>
            <input
              type="text"
              id="username"
              placeholder="your.emai@domain.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </form>
          {!statusMessage && (
            <button
              onClick={() => handleEmailSubmit()}
              className="login-button"
            >
              Passwort zurücksetzen
            </button>
          )}
          {statusMessage && <p>{statusMessage}</p>}
          {statusMessage && (
            <a href="login" className="forgot-password">
              Zurück zum Login.
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
