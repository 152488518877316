import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

import questionnaireData from '../../../../assets/data/questionnaireData.json';
import {
  dialogStyles,
  dialogContentStyles,
  tableRowStyles,
  tableCellStyles,
  errorTextStyles,
  errorCellStyles,
  tableStyles,
  tableHeadStyles,
} from './style'; // Import styles

function Questionnaire({ onSubmit, isVisible }) {
  const [open, setOpen] = useState(false);
  const [responses, setResponses] = useState({});
  const [errors, setErrors] = useState({});
  const [comment, setComment] = useState('');

  // Function to check if questionnaire should be shown
  const checkVisibility = () => {
    const lastShown = sessionStorage.getItem('presada:lastQuestionnaireTime');
    const now = Date.now();

    if (!lastShown || now - parseInt(lastShown, 10) > 2 * 60 * 60 * 1000) {
      return true;
    } else {
      return false;
    }
  };

  // Run effect when `isVisible` changes (whenever Questionnaire is rendered)
  useEffect(() => {
    if (isVisible) {
      if (checkVisibility()) {
        setOpen(true);
      } else {
        // invoke logic after questionnaire is not shown (forwarding to next page and resetting variables)
        onSubmit(null);
      }
    }
  }, [isVisible, onSubmit]);

  const handleClose = (event, reason) => {
    // Prevent closing when clicking somewhere else or pressing escape
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

    // is not shown any more on the training page
    setOpen(false);

    // Save time when questionnaire was last shown to show it only after 2 hours to the user again
    sessionStorage.setItem('presada:lastQuestionnaireTime', Date.now());

    const submission = {
      responses,
      comment,
      time: Date.now(),
      questions: questionnaireData.questions,
      options: questionnaireData.options,
    };

    // invoke outer logic after questionnaire is shown (forwarding to next page and resetting variables)
    onSubmit({ submission });
  };

  const handleChange = (questionIndex, value) => {
    setResponses({ ...responses, [questionIndex]: value });
    setErrors({ ...errors, [questionIndex]: false });
  };

  const handleTextFieldChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    const newErrors = {};
    questionnaireData.questions.forEach((_, index) => {
      if (!responses[index]) {
        newErrors[index] = true;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setOpen(false);
      sessionStorage.setItem('presada:lastQuestionnaireTime', Date.now());
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={dialogStyles}
    >
      <DialogTitle>Wie sehr stimmen Sie den folgenden Aussagen zu?</DialogTitle>
      <DialogContent sx={dialogContentStyles}>
        <TableContainer component={Paper}>
          <Table sx={tableStyles}>
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                <TableCell></TableCell>
                {questionnaireData.options.map((option, i) => (
                  <TableCell key={i} align="center">
                    {option}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questionnaireData.questions.map((question, index) => (
                <TableRow sx={tableRowStyles(errors[index])}>
                  <TableCell sx={tableCellStyles}>
                    <TableRow>
                      <TableCell sx={tableCellStyles}>{question}</TableCell>
                    </TableRow>
                    <TableRow>
                      {errors[index] && (
                        <TableCell
                          colSpan={questionnaireData.options.length + 1}
                          sx={errorCellStyles}
                        >
                          <FormHelperText sx={errorTextStyles}>
                            Pflichtfeld
                          </FormHelperText>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableCell>
                  {questionnaireData.options.map((option, i) => (
                    <TableCell key={i} align="center" sx={tableCellStyles}>
                      <Radio
                        checked={responses[index] === String(i)}
                        onChange={() => handleChange(index, String(i))}
                        value={option}
                        name={`q${index}`}
                        color={errors[index] ? 'error' : 'primary'}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={2}>
          <TextField
            fullWidth
            margin="normal"
            multiline
            rows={3}
            placeholder="Weiteres Feedback"
            variant="outlined"
            value={comment}
            onChange={handleTextFieldChange}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Senden
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Questionnaire;
