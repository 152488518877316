import React from 'react';

import { MessageContainer, HighlightedText } from './style';

const NoDataErrorMessage = () => {
  return (
    <MessageContainer>
      Nicht genügend Trainingsdaten vorhanden. <br />
      Bitte führen Sie <HighlightedText>
        mindestens 5 Trainings
      </HighlightedText>{' '}
      durch.
    </MessageContainer>
  );
};

export default NoDataErrorMessage;
