import styled from 'styled-components';
import Rating from '@mui/material/Rating';

export const GridItem = styled.div`
  height: 100%;
  min-height: 240px;
  max-height: 240px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  max-width: 240px;
  justify-content: flex-start;
  align-items: start;
  background: white;
  border-radius: 4px;
  padding-top: 12px;
  padding-left: 24px;
  box-shadow: var(--shadow-default);
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--black);
  margin-bottom: 2vh;
`;

export const StyledArrow = styled.span`
  color: var(--turquoise);
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const RecentStatsTitle = styled.span`
  font-size: 0.7rem;
  font-weight: normal;
  color: var(--dark-gray);
  line-height: 1.2;
  margin-bottom: 4px;
`;

export const RecentStatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 8px;
`;

export const RecentStatsValue = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0.4rem 0rem 0.3rem;
  line-height: 1;
  max-width: 100%;
  white-space: nowrap;
`;

export const RecentStyledScore = styled(Rating)`
  /*
   Using "&&" increases CSS specificity to override MUI's default styles.
   MUI applies styles with high specificity, so without "&&", our styles
   might get overridden by the built-in ones. "&&" duplicates the selector,
   making it more specific and ensuring our styles take priority.
  */
  && {
    color: var(--turquoise);
    font-size: 1.5rem;
    // margin-top: 0.5rem;
  }
`;

export const MonthlyStatsTitle = styled.span`
  font-size: 0.7rem;
  font-weight: 200;
  color: #575757;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 16px;
`;

export const MonthlyStatsWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const MonthlyStatsValue = styled.p`
  font-size: 1.2rem;
  font-weight: 200;
  margin: 0px;
  color: var(--dark-gray);
  max-width: 100%;
  white-space: nowrap;
`;

export const MonthlyStyledScore = styled(Rating)`
  && {
    color: #bbb;
    font-size: 1.2rem;
    margin-bottom: 2px;
  }
`;
